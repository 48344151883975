/* eslint-disable */  
/// <reference path="./custom.d.ts" />
// tslint:disable
  // @ts-nocheck
/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */

import url from "url";
import * as isomorphicFetch from "isomorphic-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "http://api-dev.admaru.net".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = isomorphicFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface AccountBean
 */
export interface AccountBean {
    /**
     * 
     * @type {number}
     * @memberof AccountBean
     */
    id?: number;
    /**
     * Account email
     * @type {string}
     * @memberof AccountBean
     */
    email: string;
    /**
     * Account name
     * @type {string}
     * @memberof AccountBean
     */
    name: string;
    /**
     * Account password
     * @type {string}
     * @memberof AccountBean
     */
    password?: string;
    /**
     * Account access role
     * @type {string}
     * @memberof AccountBean
     */
    role: AccountBean.RoleEnum;
    /**
     * Account publisher ID
     * @type {number}
     * @memberof AccountBean
     */
    publisherId?: number;
}

/**
 * @export
 * @namespace AccountBean
 */
export namespace AccountBean {
    /**
     * @export
     * @enum {string}
     */
    export enum RoleEnum {
        Admin = <any> 'Admin',
        Publisher = <any> 'Publisher'
    }
}
/**
 * 
 * @export
 * @interface AccountBeanInList
 */
export interface AccountBeanInList {
    /**
     * 
     * @type {number}
     * @memberof AccountBeanInList
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountBeanInList
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountBeanInList
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountBeanInList
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountBeanInList
     */
    role?: AccountBeanInList.RoleEnum;
    /**
     * 
     * @type {number}
     * @memberof AccountBeanInList
     */
    publisherId?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountBeanInList
     */
    publisherName?: string;
}

/**
 * @export
 * @namespace AccountBeanInList
 */
export namespace AccountBeanInList {
    /**
     * @export
     * @enum {string}
     */
    export enum RoleEnum {
        Admin = <any> 'Admin',
        Publisher = <any> 'Publisher'
    }
}
/**
 * 
 * @export
 * @interface AccountInfoBean
 */
export interface AccountInfoBean {
    /**
     * 
     * @type {boolean}
     * @memberof AccountInfoBean
     */
    isLoggedIn?: boolean;
    /**
     * 
     * @type {AccountBean}
     * @memberof AccountInfoBean
     */
    accountBean?: AccountBean;
}
/**
 * 
 * @export
 * @interface AdSizeBean
 */
export interface AdSizeBean {
    /**
     * 
     * @type {number}
     * @memberof AdSizeBean
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AdSizeBean
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdSizeBean
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof AdSizeBean
     */
    width: number;
    /**
     * 
     * @type {number}
     * @memberof AdSizeBean
     */
    height: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof AdSizeBean
     */
    parentIds?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof AdSizeBean
     */
    childIds?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdSizeBean
     */
    parentNames?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdSizeBean
     */
    childNames?: Array<string>;
}
/**
 * 
 * @export
 * @interface AdUnitBean
 */
export interface AdUnitBean {
    /**
     * 
     * @type {number}
     * @memberof AdUnitBean
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AdUnitBean
     */
    active: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdUnitBean
     */
    siteId: number;
    /**
     * 
     * @type {string}
     * @memberof AdUnitBean
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof AdUnitBean
     */
    bidFloor: number;
    /**
     * 
     * @type {string}
     * @memberof AdUnitBean
     */
    adUnitType: AdUnitBean.AdUnitTypeEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof AdUnitBean
     */
    bannerAdSizeIds?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof AdUnitBean
     */
    videoAdSizeId?: number;
    /**
     * 
     * @type {string}
     * @memberof AdUnitBean
     */
    auctionType: AdUnitBean.AuctionTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof AdUnitBean
     */
    privateAuction: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof AdUnitBean
     */
    dsps?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof AdUnitBean
     */
    dealIds?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof AdUnitBean
     */
    test: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof AdUnitBean
     */
    linkedCampaignIds?: Array<number>;
    /**
     * 
     * @type {FilterBean}
     * @memberof AdUnitBean
     */
    filterBean?: FilterBean;
    /**
     * 
     * @type {string}
     * @memberof AdUnitBean
     */
    configId?: string;
    /**
     * 
     * @type {Array<TypedDspIdMappingBean>}
     * @memberof AdUnitBean
     */
    dspIdMappings?: Array<TypedDspIdMappingBean>;
}

/**
 * @export
 * @namespace AdUnitBean
 */
export namespace AdUnitBean {
    /**
     * @export
     * @enum {string}
     */
    export enum AdUnitTypeEnum {
        VIDEO = <any> 'VIDEO',
        BANNER = <any> 'BANNER',
        MULTI = <any> 'MULTI',
        NATIVE = <any> 'NATIVE'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum AuctionTypeEnum {
        FIRSTPRICE = <any> 'FIRST_PRICE',
        SECONDPRICE = <any> 'SECOND_PRICE'
    }
}
/**
 * 
 * @export
 * @interface AdUnitBeanInList
 */
export interface AdUnitBeanInList {
    /**
     * 
     * @type {number}
     * @memberof AdUnitBeanInList
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AdUnitBeanInList
     */
    active?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdUnitBeanInList
     */
    siteId?: number;
    /**
     * 
     * @type {string}
     * @memberof AdUnitBeanInList
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof AdUnitBeanInList
     */
    bidFloor?: number;
    /**
     * 
     * @type {string}
     * @memberof AdUnitBeanInList
     */
    adUnitType?: AdUnitBeanInList.AdUnitTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AdUnitBeanInList
     */
    auctionType?: AdUnitBeanInList.AuctionTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof AdUnitBeanInList
     */
    privateAuction?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdUnitBeanInList
     */
    test?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof AdUnitBeanInList
     */
    linkedCampaignIds?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdUnitBeanInList
     */
    linkedCampaignNames?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AdUnitBeanInList
     */
    videoAdSizeId?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof AdUnitBeanInList
     */
    bannerAdSizeIds?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof AdUnitBeanInList
     */
    dspIds?: Array<number>;
    /**
     * 
     * @type {FilterBean}
     * @memberof AdUnitBeanInList
     */
    filterBean?: FilterBean;
    /**
     * 
     * @type {number}
     * @memberof AdUnitBeanInList
     */
    publisherId?: number;
    /**
     * 
     * @type {string}
     * @memberof AdUnitBeanInList
     */
    publisherName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdUnitBeanInList
     */
    siteName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdUnitBeanInList
     */
    biddingCurrency?: AdUnitBeanInList.BiddingCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof AdUnitBeanInList
     */
    videoAdSizeName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdUnitBeanInList
     */
    bannerAdSizeNames?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdUnitBeanInList
     */
    dspNames?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdUnitBeanInList
     */
    dealNames?: Array<string>;
    /**
     * 
     * @type {Array<DspIdMappingBean>}
     * @memberof AdUnitBeanInList
     */
    dspIdMappingBeans?: Array<DspIdMappingBean>;
    /**
     * 
     * @type {string}
     * @memberof AdUnitBeanInList
     */
    configId?: string;
}

/**
 * @export
 * @namespace AdUnitBeanInList
 */
export namespace AdUnitBeanInList {
    /**
     * @export
     * @enum {string}
     */
    export enum AdUnitTypeEnum {
        VIDEO = <any> 'VIDEO',
        BANNER = <any> 'BANNER',
        MULTI = <any> 'MULTI',
        NATIVE = <any> 'NATIVE'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum AuctionTypeEnum {
        FIRSTPRICE = <any> 'FIRST_PRICE',
        SECONDPRICE = <any> 'SECOND_PRICE'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum BiddingCurrencyEnum {
        USD = <any> 'USD',
        KRW = <any> 'KRW'
    }
}
/**
 * 
 * @export
 * @interface AdsTxtBean
 */
export interface AdsTxtBean {
    /**
     * 
     * @type {string}
     * @memberof AdsTxtBean
     */
    adsTxt: string;
}
/**
 * 
 * @export
 * @interface ApiDirectvideoBody
 */
export interface ApiDirectvideoBody {
    /**
     * 
     * @type {Blob}
     * @memberof ApiDirectvideoBody
     */
    video: Blob;
}
/**
 * 
 * @export
 * @interface ApiImageBody
 */
export interface ApiImageBody {
    /**
     * 
     * @type {Blob}
     * @memberof ApiImageBody
     */
    image: Blob;
}
/**
 * 
 * @export
 * @interface BrowserBean
 */
export interface BrowserBean {
    /**
     * 
     * @type {string}
     * @memberof BrowserBean
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface CountryBean
 */
export interface CountryBean {
    /**
     * 
     * @type {number}
     * @memberof CountryBean
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CountryBean
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CountryBean
     */
    iso3: string;
}
/**
 * 
 * @export
 * @interface CountrySubdivisionBean
 */
export interface CountrySubdivisionBean {
    /**
     * 
     * @type {number}
     * @memberof CountrySubdivisionBean
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof CountrySubdivisionBean
     */
    countryId: number;
    /**
     * 
     * @type {string}
     * @memberof CountrySubdivisionBean
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CountrySubdivisionBean
     */
    iso: string;
}
/**
 * 
 * @export
 * @interface CurrencyBean
 */
export interface CurrencyBean {
    /**
     * 
     * @type {number}
     * @memberof CurrencyBean
     */
    rateKRW?: number;
    /**
     * 
     * @type {string}
     * @memberof CurrencyBean
     */
    date?: string;
}
/**
 * 
 * @export
 * @interface DashboardChartBean
 */
export interface DashboardChartBean {
    /**
     * 
     * @type {Array<RawStatRow>}
     * @memberof DashboardChartBean
     */
    chart?: Array<RawStatRow>;
}
/**
 * 
 * @export
 * @interface DashboardChartRequestBean
 */
export interface DashboardChartRequestBean {
    /**
     * 
     * @type {string}
     * @memberof DashboardChartRequestBean
     */
    currency: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardChartRequestBean
     */
    periodType: DashboardChartRequestBean.PeriodTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DashboardChartRequestBean
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardChartRequestBean
     */
    endDate: string;
    /**
     * 
     * @type {boolean}
     * @memberof DashboardChartRequestBean
     */
    audit: boolean;
    /**
     * 
     * @type {Array<FilterEntry>}
     * @memberof DashboardChartRequestBean
     */
    filters?: Array<FilterEntry>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DashboardChartRequestBean
     */
    values: Array<DashboardChartRequestBean.ValuesEnum>;
}

/**
 * @export
 * @namespace DashboardChartRequestBean
 */
export namespace DashboardChartRequestBean {
    /**
     * @export
     * @enum {string}
     */
    export enum PeriodTypeEnum {
        TODAY = <any> 'TODAY',
        YESTERDAY = <any> 'YESTERDAY',
        LAST3DAYS = <any> 'LAST_3_DAYS',
        LAST7DAYS = <any> 'LAST_7_DAYS',
        LAST30DAYS = <any> 'LAST_30_DAYS',
        THISMONTH = <any> 'THIS_MONTH',
        LASTMONTH = <any> 'LAST_MONTH',
        CUSTOMRANGE = <any> 'CUSTOM_RANGE'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum ValuesEnum {
        PubReq = <any> 'pubReq',
        PubBid = <any> 'pubBid',
        PubBidFloor = <any> 'pubBidFloor',
        PubBidFloorAvg = <any> 'pubBidFloorAvg',
        PubBidPrice = <any> 'pubBidPrice',
        PubBidPriceAvg = <any> 'pubBidPriceAvg',
        PubBidRate = <any> 'pubBidRate',
        PubWin = <any> 'pubWin',
        PubWinRate = <any> 'pubWinRate',
        DspReq = <any> 'dspReq',
        DspBid = <any> 'dspBid',
        DspBidPrice = <any> 'dspBidPrice',
        DspBidPriceAvg = <any> 'dspBidPriceAvg',
        DspBidRate = <any> 'dspBidRate',
        DspError = <any> 'dspError',
        DspWinRate = <any> 'dspWinRate',
        DspWinPrice = <any> 'dspWinPrice',
        DspWinPriceAvg = <any> 'dspWinPriceAvg',
        DspTaxonomyVersion = <any> 'dspTaxonomyVersion',
        Render = <any> 'render',
        Impr = <any> 'impr',
        ImprPrice = <any> 'imprPrice',
        NetImprPrice = <any> 'netImprPrice',
        ImprPriceAvg = <any> 'imprPriceAvg',
        NetImprPriceAvg = <any> 'netImprPriceAvg',
        Profit = <any> 'profit',
        ImprRate = <any> 'imprRate',
        FillRate = <any> 'fillRate',
        DspClick = <any> 'dspClick',
        DspCtr = <any> 'dspCtr',
        DirectAdClick = <any> 'directAdClick',
        DirectAdCtr = <any> 'directAdCtr',
        VastClick = <any> 'vastClick',
        VastCtr = <any> 'vastCtr',
        VastError = <any> 'vastError',
        VastStart = <any> 'vastStart',
        VastFirstQ = <any> 'vastFirstQ',
        VastMid = <any> 'vastMid',
        VastThirdQ = <any> 'vastThirdQ',
        VastComplete = <any> 'vastComplete',
        VastCloseLinear = <any> 'vastCloseLinear',
        VastSkip = <any> 'vastSkip',
        VastPause = <any> 'vastPause',
        Interstitial = <any> 'interstitial',
        Rewarded = <any> 'rewarded',
        WinError = <any> 'winError',
        RenderError = <any> 'renderError',
        ImprError = <any> 'imprError',
        ClickError = <any> 'clickError',
        ExternalImpr = <any> 'externalImpr',
        ExternalRevenue = <any> 'externalRevenue',
        VastRetrieve = <any> 'vastRetrieve',
        PlayerLoading = <any> 'playerLoading',
        VideoJsLoaded = <any> 'videoJsLoaded',
        PluginsLoaded = <any> 'pluginsLoaded',
        PlayerError = <any> 'playerError'
    }
}
/**
 * 
 * @export
 * @interface DashboardStatBean
 */
export interface DashboardStatBean {
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof DashboardStatBean
     */
    stats?: { [key: string]: number; };
}
/**
 * 
 * @export
 * @interface DashboardStatRequestBean
 */
export interface DashboardStatRequestBean {
    /**
     * 
     * @type {string}
     * @memberof DashboardStatRequestBean
     */
    currency: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardStatRequestBean
     */
    periodType: DashboardStatRequestBean.PeriodTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DashboardStatRequestBean
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardStatRequestBean
     */
    endDate: string;
    /**
     * 
     * @type {boolean}
     * @memberof DashboardStatRequestBean
     */
    audit: boolean;
    /**
     * 
     * @type {Array<FilterEntry>}
     * @memberof DashboardStatRequestBean
     */
    filters?: Array<FilterEntry>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DashboardStatRequestBean
     */
    values: Array<DashboardStatRequestBean.ValuesEnum>;
}

/**
 * @export
 * @namespace DashboardStatRequestBean
 */
export namespace DashboardStatRequestBean {
    /**
     * @export
     * @enum {string}
     */
    export enum PeriodTypeEnum {
        TODAY = <any> 'TODAY',
        YESTERDAY = <any> 'YESTERDAY',
        LAST3DAYS = <any> 'LAST_3_DAYS',
        LAST7DAYS = <any> 'LAST_7_DAYS',
        LAST30DAYS = <any> 'LAST_30_DAYS',
        THISMONTH = <any> 'THIS_MONTH',
        LASTMONTH = <any> 'LAST_MONTH',
        CUSTOMRANGE = <any> 'CUSTOM_RANGE'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum ValuesEnum {
        PubReq = <any> 'pubReq',
        PubBid = <any> 'pubBid',
        PubBidFloor = <any> 'pubBidFloor',
        PubBidFloorAvg = <any> 'pubBidFloorAvg',
        PubBidPrice = <any> 'pubBidPrice',
        PubBidPriceAvg = <any> 'pubBidPriceAvg',
        PubBidRate = <any> 'pubBidRate',
        PubWin = <any> 'pubWin',
        PubWinRate = <any> 'pubWinRate',
        DspReq = <any> 'dspReq',
        DspBid = <any> 'dspBid',
        DspBidPrice = <any> 'dspBidPrice',
        DspBidPriceAvg = <any> 'dspBidPriceAvg',
        DspBidRate = <any> 'dspBidRate',
        DspError = <any> 'dspError',
        DspWinRate = <any> 'dspWinRate',
        DspWinPrice = <any> 'dspWinPrice',
        DspWinPriceAvg = <any> 'dspWinPriceAvg',
        DspTaxonomyVersion = <any> 'dspTaxonomyVersion',
        Render = <any> 'render',
        Impr = <any> 'impr',
        ImprPrice = <any> 'imprPrice',
        NetImprPrice = <any> 'netImprPrice',
        ImprPriceAvg = <any> 'imprPriceAvg',
        NetImprPriceAvg = <any> 'netImprPriceAvg',
        Profit = <any> 'profit',
        ImprRate = <any> 'imprRate',
        FillRate = <any> 'fillRate',
        DspClick = <any> 'dspClick',
        DspCtr = <any> 'dspCtr',
        DirectAdClick = <any> 'directAdClick',
        DirectAdCtr = <any> 'directAdCtr',
        VastClick = <any> 'vastClick',
        VastCtr = <any> 'vastCtr',
        VastError = <any> 'vastError',
        VastStart = <any> 'vastStart',
        VastFirstQ = <any> 'vastFirstQ',
        VastMid = <any> 'vastMid',
        VastThirdQ = <any> 'vastThirdQ',
        VastComplete = <any> 'vastComplete',
        VastCloseLinear = <any> 'vastCloseLinear',
        VastSkip = <any> 'vastSkip',
        VastPause = <any> 'vastPause',
        Interstitial = <any> 'interstitial',
        Rewarded = <any> 'rewarded',
        WinError = <any> 'winError',
        RenderError = <any> 'renderError',
        ImprError = <any> 'imprError',
        ClickError = <any> 'clickError',
        ExternalImpr = <any> 'externalImpr',
        ExternalRevenue = <any> 'externalRevenue',
        VastRetrieve = <any> 'vastRetrieve',
        PlayerLoading = <any> 'playerLoading',
        VideoJsLoaded = <any> 'videoJsLoaded',
        PluginsLoaded = <any> 'pluginsLoaded',
        PlayerError = <any> 'playerError'
    }
}
/**
 * 
 * @export
 * @interface DashboardTopBean
 */
export interface DashboardTopBean {
    /**
     * 
     * @type {{ [key: string]: Array<RawStatRow>; }}
     * @memberof DashboardTopBean
     */
    data?: { [key: string]: Array<RawStatRow>; };
}
/**
 * 
 * @export
 * @interface DashboardTopRequestBean
 */
export interface DashboardTopRequestBean {
    /**
     * 
     * @type {string}
     * @memberof DashboardTopRequestBean
     */
    currency: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardTopRequestBean
     */
    periodType: DashboardTopRequestBean.PeriodTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DashboardTopRequestBean
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardTopRequestBean
     */
    endDate: string;
    /**
     * 
     * @type {boolean}
     * @memberof DashboardTopRequestBean
     */
    audit: boolean;
    /**
     * 
     * @type {Array<FilterEntry>}
     * @memberof DashboardTopRequestBean
     */
    filters?: Array<FilterEntry>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DashboardTopRequestBean
     */
    keys: Array<DashboardTopRequestBean.KeysEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DashboardTopRequestBean
     */
    values: Array<DashboardTopRequestBean.ValuesEnum>;
    /**
     * 
     * @type {number}
     * @memberof DashboardTopRequestBean
     */
    limit: number;
}

/**
 * @export
 * @namespace DashboardTopRequestBean
 */
export namespace DashboardTopRequestBean {
    /**
     * @export
     * @enum {string}
     */
    export enum PeriodTypeEnum {
        TODAY = <any> 'TODAY',
        YESTERDAY = <any> 'YESTERDAY',
        LAST3DAYS = <any> 'LAST_3_DAYS',
        LAST7DAYS = <any> 'LAST_7_DAYS',
        LAST30DAYS = <any> 'LAST_30_DAYS',
        THISMONTH = <any> 'THIS_MONTH',
        LASTMONTH = <any> 'LAST_MONTH',
        CUSTOMRANGE = <any> 'CUSTOM_RANGE'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum KeysEnum {
        AdUnit = <any> 'ad_unit',
        AdUnitName = <any> 'ad_unitName',
        Publisher = <any> 'publisher',
        PublisherName = <any> 'publisherName',
        Site = <any> 'site',
        SiteName = <any> 'siteName',
        DealPublicId = <any> 'dealPublicId',
        Dsp = <any> 'dsp',
        DspNoBidReason = <any> 'dspNoBidReason',
        DspErrorReason = <any> 'dspErrorReason',
        DspTaxonomyVersion = <any> 'dspTaxonomyVersion',
        DspCategories = <any> 'dspCategories',
        SiteTaxonomyVersion = <any> 'siteTaxonomyVersion',
        SiteCategories = <any> 'siteCategories',
        DspName = <any> 'dspName',
        AdSize = <any> 'adSize',
        DirectAd = <any> 'directAd',
        DirectAdName = <any> 'directAdName',
        DirectCampaign = <any> 'directCampaign',
        DirectCampaignName = <any> 'directCampaignName',
        Day = <any> 'day',
        Hour = <any> 'hour',
        Device = <any> 'device',
        Country = <any> 'country',
        CountrySubdivision = <any> 'countrySubdivision',
        Domain = <any> 'domain',
        AdvertiserDomains = <any> 'advertiserDomains',
        Os = <any> 'os',
        Browser = <any> 'browser',
        ReqType = <any> 'reqType',
        AdUnitType = <any> 'adUnitType',
        CreativeType = <any> 'creativeType',
        VideoType = <any> 'videoType',
        PublisherUserIdType = <any> 'publisherUserIdType',
        DspUserIdType = <any> 'dspUserIdType',
        EventErrorReason = <any> 'eventErrorReason',
        ErrorStatus = <any> 'errorStatus'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum ValuesEnum {
        PubReq = <any> 'pubReq',
        PubBid = <any> 'pubBid',
        PubBidFloor = <any> 'pubBidFloor',
        PubBidFloorAvg = <any> 'pubBidFloorAvg',
        PubBidPrice = <any> 'pubBidPrice',
        PubBidPriceAvg = <any> 'pubBidPriceAvg',
        PubBidRate = <any> 'pubBidRate',
        PubWin = <any> 'pubWin',
        PubWinRate = <any> 'pubWinRate',
        DspReq = <any> 'dspReq',
        DspBid = <any> 'dspBid',
        DspBidPrice = <any> 'dspBidPrice',
        DspBidPriceAvg = <any> 'dspBidPriceAvg',
        DspBidRate = <any> 'dspBidRate',
        DspError = <any> 'dspError',
        DspWinRate = <any> 'dspWinRate',
        DspWinPrice = <any> 'dspWinPrice',
        DspWinPriceAvg = <any> 'dspWinPriceAvg',
        DspTaxonomyVersion = <any> 'dspTaxonomyVersion',
        Render = <any> 'render',
        Impr = <any> 'impr',
        ImprPrice = <any> 'imprPrice',
        NetImprPrice = <any> 'netImprPrice',
        ImprPriceAvg = <any> 'imprPriceAvg',
        NetImprPriceAvg = <any> 'netImprPriceAvg',
        Profit = <any> 'profit',
        ImprRate = <any> 'imprRate',
        FillRate = <any> 'fillRate',
        DspClick = <any> 'dspClick',
        DspCtr = <any> 'dspCtr',
        DirectAdClick = <any> 'directAdClick',
        DirectAdCtr = <any> 'directAdCtr',
        VastClick = <any> 'vastClick',
        VastCtr = <any> 'vastCtr',
        VastError = <any> 'vastError',
        VastStart = <any> 'vastStart',
        VastFirstQ = <any> 'vastFirstQ',
        VastMid = <any> 'vastMid',
        VastThirdQ = <any> 'vastThirdQ',
        VastComplete = <any> 'vastComplete',
        VastCloseLinear = <any> 'vastCloseLinear',
        VastSkip = <any> 'vastSkip',
        VastPause = <any> 'vastPause',
        Interstitial = <any> 'interstitial',
        Rewarded = <any> 'rewarded',
        WinError = <any> 'winError',
        RenderError = <any> 'renderError',
        ImprError = <any> 'imprError',
        ClickError = <any> 'clickError',
        ExternalImpr = <any> 'externalImpr',
        ExternalRevenue = <any> 'externalRevenue',
        VastRetrieve = <any> 'vastRetrieve',
        PlayerLoading = <any> 'playerLoading',
        VideoJsLoaded = <any> 'videoJsLoaded',
        PluginsLoaded = <any> 'pluginsLoaded',
        PlayerError = <any> 'playerError'
    }
}
/**
 * 
 * @export
 * @interface DealBean
 */
export interface DealBean {
    /**
     * 
     * @type {number}
     * @memberof DealBean
     */
    id?: number;
    /**
     * 
     * @type {FilterBean}
     * @memberof DealBean
     */
    filterBean?: FilterBean;
    /**
     * 
     * @type {number}
     * @memberof DealBean
     */
    publisherOwnerId: number;
    /**
     * 
     * @type {string}
     * @memberof DealBean
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DealBean
     */
    dealId: string;
    /**
     * 
     * @type {number}
     * @memberof DealBean
     */
    bidFloor: number;
    /**
     * 
     * @type {string}
     * @memberof DealBean
     */
    auctionType: DealBean.AuctionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DealBean
     */
    dealType: DealBean.DealTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DealBean
     */
    whiteListSeatIds?: string;
    /**
     * 
     * @type {string}
     * @memberof DealBean
     */
    whiteListDomains?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof DealBean
     */
    linkedAdUnitIds?: Array<number>;
}

/**
 * @export
 * @namespace DealBean
 */
export namespace DealBean {
    /**
     * @export
     * @enum {string}
     */
    export enum AuctionTypeEnum {
        FIRSTPRICE = <any> 'FIRST_PRICE',
        SECONDPRICE = <any> 'SECOND_PRICE'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum DealTypeEnum {
        PRIVATEAUCTION = <any> 'PRIVATE_AUCTION',
        PREFERREDDEAL = <any> 'PREFERRED_DEAL'
    }
}
/**
 * 
 * @export
 * @interface DealBeanInList
 */
export interface DealBeanInList {
    /**
     * 
     * @type {number}
     * @memberof DealBeanInList
     */
    id?: number;
    /**
     * 
     * @type {FilterBean}
     * @memberof DealBeanInList
     */
    filterBean?: FilterBean;
    /**
     * 
     * @type {number}
     * @memberof DealBeanInList
     */
    publisherOwnerId?: number;
    /**
     * 
     * @type {string}
     * @memberof DealBeanInList
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DealBeanInList
     */
    dealId?: string;
    /**
     * 
     * @type {number}
     * @memberof DealBeanInList
     */
    bidFloor?: number;
    /**
     * 
     * @type {string}
     * @memberof DealBeanInList
     */
    currencyType?: DealBeanInList.CurrencyTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DealBeanInList
     */
    auctionType?: DealBeanInList.AuctionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DealBeanInList
     */
    dealType?: DealBeanInList.DealTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DealBeanInList
     */
    whiteListSeatIds?: string;
    /**
     * 
     * @type {string}
     * @memberof DealBeanInList
     */
    whiteListDomains?: string;
    /**
     * 
     * @type {Array<NamedBean>}
     * @memberof DealBeanInList
     */
    linkedAdUnits?: Array<NamedBean>;
    /**
     * 
     * @type {string}
     * @memberof DealBeanInList
     */
    ownerName?: string;
}

/**
 * @export
 * @namespace DealBeanInList
 */
export namespace DealBeanInList {
    /**
     * @export
     * @enum {string}
     */
    export enum CurrencyTypeEnum {
        USD = <any> 'USD',
        KRW = <any> 'KRW'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum AuctionTypeEnum {
        FIRSTPRICE = <any> 'FIRST_PRICE',
        SECONDPRICE = <any> 'SECOND_PRICE'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum DealTypeEnum {
        PRIVATEAUCTION = <any> 'PRIVATE_AUCTION',
        PREFERREDDEAL = <any> 'PREFERRED_DEAL'
    }
}
/**
 * 
 * @export
 * @interface DirectAdBean
 */
export interface DirectAdBean {
    /**
     * 
     * @type {number}
     * @memberof DirectAdBean
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DirectAdBean
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof DirectAdBean
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DirectAdBean
     */
    rtbDetailsType: DirectAdBean.RtbDetailsTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DirectAdBean
     */
    directAdCreativeType: DirectAdBean.DirectAdCreativeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DirectAdBean
     */
    contentType: DirectAdBean.ContentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DirectAdBean
     */
    adm?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectAdBean
     */
    vastUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DirectAdBean
     */
    unwrapVast: boolean;
    /**
     * 
     * @type {number}
     * @memberof DirectAdBean
     */
    imageId?: number;
    /**
     * 
     * @type {string}
     * @memberof DirectAdBean
     */
    imageUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof DirectAdBean
     */
    videoId?: number;
    /**
     * 
     * @type {string}
     * @memberof DirectAdBean
     */
    videoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectAdBean
     */
    videoStatus?: DirectAdBean.VideoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof DirectAdBean
     */
    clickUrl: string;
    /**
     * 
     * @type {number}
     * @memberof DirectAdBean
     */
    adSizeId: number;
}

/**
 * @export
 * @namespace DirectAdBean
 */
export namespace DirectAdBean {
    /**
     * @export
     * @enum {string}
     */
    export enum RtbDetailsTypeEnum {
        SITE = <any> 'SITE',
        APP = <any> 'APP'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum DirectAdCreativeTypeEnum {
        VIDEO = <any> 'VIDEO',
        BANNER = <any> 'BANNER'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum ContentTypeEnum {
        IMAGE = <any> 'IMAGE',
        VIDEOFILE = <any> 'VIDEO_FILE',
        ADM = <any> 'ADM',
        VASTURL = <any> 'VAST_URL',
        VPOST = <any> 'V_POST'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum VideoStatusEnum {
        CREATED = <any> 'CREATED',
        CONNECTEDWITHAD = <any> 'CONNECTED_WITH_AD',
        STARTEDCOMPRESSION = <any> 'STARTED_COMPRESSION',
        COMPRESSED = <any> 'COMPRESSED',
        COMPRESSIONFAILED = <any> 'COMPRESSION_FAILED'
    }
}
/**
 * 
 * @export
 * @interface DirectAdBeanInList
 */
export interface DirectAdBeanInList {
    /**
     * 
     * @type {number}
     * @memberof DirectAdBeanInList
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DirectAdBeanInList
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DirectAdBeanInList
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectAdBeanInList
     */
    adm?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectAdBeanInList
     */
    directAdCreativeType?: DirectAdBeanInList.DirectAdCreativeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DirectAdBeanInList
     */
    contentType?: DirectAdBeanInList.ContentTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof DirectAdBeanInList
     */
    adSizeId?: number;
    /**
     * 
     * @type {number}
     * @memberof DirectAdBeanInList
     */
    imageId?: number;
    /**
     * 
     * @type {string}
     * @memberof DirectAdBeanInList
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectAdBeanInList
     */
    clickUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectAdBeanInList
     */
    videoStatus?: DirectAdBeanInList.VideoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof DirectAdBeanInList
     */
    adSizeName?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectAdBeanInList
     */
    rtbDetailsType?: DirectAdBeanInList.RtbDetailsTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof DirectAdBeanInList
     */
    linkedCampaigns?: Array<string>;
}

/**
 * @export
 * @namespace DirectAdBeanInList
 */
export namespace DirectAdBeanInList {
    /**
     * @export
     * @enum {string}
     */
    export enum DirectAdCreativeTypeEnum {
        VIDEO = <any> 'VIDEO',
        BANNER = <any> 'BANNER'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum ContentTypeEnum {
        IMAGE = <any> 'IMAGE',
        VIDEOFILE = <any> 'VIDEO_FILE',
        ADM = <any> 'ADM',
        VASTURL = <any> 'VAST_URL',
        VPOST = <any> 'V_POST'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum VideoStatusEnum {
        CREATED = <any> 'CREATED',
        CONNECTEDWITHAD = <any> 'CONNECTED_WITH_AD',
        STARTEDCOMPRESSION = <any> 'STARTED_COMPRESSION',
        COMPRESSED = <any> 'COMPRESSED',
        COMPRESSIONFAILED = <any> 'COMPRESSION_FAILED'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum RtbDetailsTypeEnum {
        SITE = <any> 'SITE',
        APP = <any> 'APP'
    }
}
/**
 * 
 * @export
 * @interface DirectCampaignBean
 */
export interface DirectCampaignBean {
    /**
     * 
     * @type {number}
     * @memberof DirectCampaignBean
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DirectCampaignBean
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof DirectCampaignBean
     */
    active: boolean;
    /**
     * 
     * @type {number}
     * @memberof DirectCampaignBean
     */
    usdCpm: number;
    /**
     * 
     * @type {number}
     * @memberof DirectCampaignBean
     */
    impressionLimit: number;
    /**
     * 
     * @type {Date}
     * @memberof DirectCampaignBean
     */
    startedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DirectCampaignBean
     */
    endedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof DirectCampaignBean
     */
    cappingType: DirectCampaignBean.CappingTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DirectCampaignBean
     */
    cappingPassingType: DirectCampaignBean.CappingPassingTypeEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof DirectCampaignBean
     */
    linkedAdUnits?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DirectCampaignBean
     */
    linkedAds?: Array<number>;
    /**
     * 
     * @type {FilterBean}
     * @memberof DirectCampaignBean
     */
    targeting?: FilterBean;
}

/**
 * @export
 * @namespace DirectCampaignBean
 */
export namespace DirectCampaignBean {
    /**
     * @export
     * @enum {string}
     */
    export enum CappingTypeEnum {
        DAY = <any> 'DAY',
        MONTH = <any> 'MONTH',
        TOTAL = <any> 'TOTAL'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum CappingPassingTypeEnum {
        EVEN = <any> 'EVEN',
        ASAP = <any> 'ASAP'
    }
}
/**
 * 
 * @export
 * @interface DirectCampaignBeanInList
 */
export interface DirectCampaignBeanInList {
    /**
     * 
     * @type {number}
     * @memberof DirectCampaignBeanInList
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DirectCampaignBeanInList
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DirectCampaignBeanInList
     */
    active?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DirectCampaignBeanInList
     */
    usdCpm?: number;
    /**
     * 
     * @type {number}
     * @memberof DirectCampaignBeanInList
     */
    impressionLimit?: number;
    /**
     * 
     * @type {Date}
     * @memberof DirectCampaignBeanInList
     */
    startedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DirectCampaignBeanInList
     */
    endedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof DirectCampaignBeanInList
     */
    cappingType?: DirectCampaignBeanInList.CappingTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DirectCampaignBeanInList
     */
    cappingPassingType?: DirectCampaignBeanInList.CappingPassingTypeEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof DirectCampaignBeanInList
     */
    linkedAdUnitIds?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DirectCampaignBeanInList
     */
    linkedAdUnitNames?: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DirectCampaignBeanInList
     */
    linkedAdIds?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DirectCampaignBeanInList
     */
    linkedAdNames?: Array<string>;
    /**
     * 
     * @type {FilterBean}
     * @memberof DirectCampaignBeanInList
     */
    targeting?: FilterBean;
}

/**
 * @export
 * @namespace DirectCampaignBeanInList
 */
export namespace DirectCampaignBeanInList {
    /**
     * @export
     * @enum {string}
     */
    export enum CappingTypeEnum {
        DAY = <any> 'DAY',
        MONTH = <any> 'MONTH',
        TOTAL = <any> 'TOTAL'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum CappingPassingTypeEnum {
        EVEN = <any> 'EVEN',
        ASAP = <any> 'ASAP'
    }
}
/**
 * 
 * @export
 * @interface DirectVideoBean
 */
export interface DirectVideoBean {
    /**
     * 
     * @type {number}
     * @memberof DirectVideoBean
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DirectVideoBean
     */
    videoUrl?: string;
}
/**
 * 
 * @export
 * @interface DspBean
 */
export interface DspBean {
    /**
     * 
     * @type {number}
     * @memberof DspBean
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DspBean
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DspBean
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DspBean
     */
    apikey: string;
    /**
     * 
     * @type {number}
     * @memberof DspBean
     */
    timeout?: number;
    /**
     * 
     * @type {number}
     * @memberof DspBean
     */
    qpsLimit?: number;
    /**
     * 
     * @type {string}
     * @memberof DspBean
     */
    endpointUrl: string;
    /**
     * 
     * @type {string}
     * @memberof DspBean
     */
    userSyncEndpoint?: string;
    /**
     * 
     * @type {string}
     * @memberof DspBean
     */
    userSyncUidMacro?: string;
    /**
     * 
     * @type {string}
     * @memberof DspBean
     */
    registrationId: string;
    /**
     * 
     * @type {boolean}
     * @memberof DspBean
     */
    unwrapVast?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DspBean
     */
    compressionType?: DspBean.CompressionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DspBean
     */
    bidRequestAdapterType: DspBean.BidRequestAdapterTypeEnum;
    /**
     * 
     * @type {FilterBean}
     * @memberof DspBean
     */
    filterBean?: FilterBean;
    /**
     * 
     * @type {string}
     * @memberof DspBean
     */
    iabTaxonomyVersion?: DspBean.IabTaxonomyVersionEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof DspBean
     */
    blockedCategories?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof DspBean
     */
    multiImp?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DspBean
     */
    acceptKnownBuyerOnly?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DspBean
     */
    samplingRate?: number;
}

/**
 * @export
 * @namespace DspBean
 */
export namespace DspBean {
    /**
     * @export
     * @enum {string}
     */
    export enum CompressionTypeEnum {
        NONE = <any> 'NONE',
        GZIP = <any> 'GZIP',
        SNAPPY = <any> 'SNAPPY'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum BidRequestAdapterTypeEnum {
        Admixer = <any> 'Admixer',
        NHNAce = <any> 'NHNAce',
        Magnite = <any> 'Magnite',
        Index = <any> 'Index',
        OneTag = <any> 'OneTag',
        AdpackerVideo = <any> 'AdpackerVideo',
        AdpackerDisplay = <any> 'AdpackerDisplay',
        Pubmatic = <any> 'Pubmatic',
        MezzoMedia = <any> 'MezzoMedia',
        MotivIntelligence = <any> 'MotivIntelligence',
        Default = <any> 'Default'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum IabTaxonomyVersionEnum {
        _10 = <any> 'VERSION_10',
        _22 = <any> 'VERSION_22'
    }
}
/**
 * 
 * @export
 * @interface DspBeanInList
 */
export interface DspBeanInList {
    /**
     * 
     * @type {number}
     * @memberof DspBeanInList
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DspBeanInList
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DspBeanInList
     */
    active?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DspBeanInList
     */
    timeout?: number;
    /**
     * 
     * @type {number}
     * @memberof DspBeanInList
     */
    qpsLimit?: number;
    /**
     * 
     * @type {string}
     * @memberof DspBeanInList
     */
    endpointUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof DspBeanInList
     */
    userSyncEndpoint?: string;
    /**
     * 
     * @type {string}
     * @memberof DspBeanInList
     */
    userSyncUidMacro?: string;
    /**
     * 
     * @type {string}
     * @memberof DspBeanInList
     */
    registrationId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DspBeanInList
     */
    unwrapVast?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DspBeanInList
     */
    compressionType?: DspBeanInList.CompressionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DspBeanInList
     */
    bidRequestAdapterType?: DspBeanInList.BidRequestAdapterTypeEnum;
    /**
     * 
     * @type {FilterBean}
     * @memberof DspBeanInList
     */
    filterBean?: FilterBean;
    /**
     * 
     * @type {boolean}
     * @memberof DspBeanInList
     */
    multiImp?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DspBeanInList
     */
    iabTaxonomyVersion?: DspBeanInList.IabTaxonomyVersionEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof DspBeanInList
     */
    categories?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DspBeanInList
     */
    blockedCategories?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof DspBeanInList
     */
    samplingRate?: number;
}

/**
 * @export
 * @namespace DspBeanInList
 */
export namespace DspBeanInList {
    /**
     * @export
     * @enum {string}
     */
    export enum CompressionTypeEnum {
        NONE = <any> 'NONE',
        GZIP = <any> 'GZIP',
        SNAPPY = <any> 'SNAPPY'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum BidRequestAdapterTypeEnum {
        Admixer = <any> 'Admixer',
        NHNAce = <any> 'NHNAce',
        Magnite = <any> 'Magnite',
        Index = <any> 'Index',
        OneTag = <any> 'OneTag',
        AdpackerVideo = <any> 'AdpackerVideo',
        AdpackerDisplay = <any> 'AdpackerDisplay',
        Pubmatic = <any> 'Pubmatic',
        MezzoMedia = <any> 'MezzoMedia',
        MotivIntelligence = <any> 'MotivIntelligence',
        Default = <any> 'Default'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum IabTaxonomyVersionEnum {
        _10 = <any> 'VERSION_10',
        _22 = <any> 'VERSION_22'
    }
}
/**
 * 
 * @export
 * @interface DspIdMappingBean
 */
export interface DspIdMappingBean {
    /**
     * 
     * @type {number}
     * @memberof DspIdMappingBean
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof DspIdMappingBean
     */
    entityId?: number;
    /**
     * 
     * @type {number}
     * @memberof DspIdMappingBean
     */
    dspId?: number;
    /**
     * 
     * @type {string}
     * @memberof DspIdMappingBean
     */
    entityIdInDsp?: string;
}
/**
 * 
 * @export
 * @interface DspReportRow
 */
export interface DspReportRow {
    /**
     * 
     * @type {string}
     * @memberof DspReportRow
     */
    date?: string;
    /**
     * 
     * @type {number}
     * @memberof DspReportRow
     */
    requests?: number;
    /**
     * 
     * @type {number}
     * @memberof DspReportRow
     */
    bids?: number;
    /**
     * 
     * @type {number}
     * @memberof DspReportRow
     */
    wins?: number;
    /**
     * 
     * @type {number}
     * @memberof DspReportRow
     */
    impressions?: number;
    /**
     * 
     * @type {number}
     * @memberof DspReportRow
     */
    revenue?: number;
}
/**
 * 
 * @export
 * @interface DspReportStats
 */
export interface DspReportStats {
    /**
     * 
     * @type {Array<DspReportRow>}
     * @memberof DspReportStats
     */
    stats?: Array<DspReportRow>;
}
/**
 * 
 * @export
 * @interface EmailDetailsDTO
 */
export interface EmailDetailsDTO {
    /**
     * 
     * @type {string}
     * @memberof EmailDetailsDTO
     */
    recipient?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailDetailsDTO
     */
    msgBody?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailDetailsDTO
     */
    subject?: string;
}
/**
 * 
 * @export
 * @interface FilterBean
 */
export interface FilterBean {
    /**
     * 
     * @type {number}
     * @memberof FilterBean
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FilterBean
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof FilterBean
     */
    countryListType?: FilterBean.CountryListTypeEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof FilterBean
     */
    countryIds?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof FilterBean
     */
    countrySubdivisionListType?: FilterBean.CountrySubdivisionListTypeEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof FilterBean
     */
    countrySubdivisionIds?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof FilterBean
     */
    deviceListType?: FilterBean.DeviceListTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterBean
     */
    deviceList?: Array<FilterBean.DeviceListEnum>;
    /**
     * 
     * @type {string}
     * @memberof FilterBean
     */
    dayPart?: string;
}

/**
 * @export
 * @namespace FilterBean
 */
export namespace FilterBean {
    /**
     * @export
     * @enum {string}
     */
    export enum CountryListTypeEnum {
        INCLUDEALL = <any> 'INCLUDE_ALL',
        EXCLUDESELECTED = <any> 'EXCLUDE_SELECTED',
        INCLUDESELECTED = <any> 'INCLUDE_SELECTED'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum CountrySubdivisionListTypeEnum {
        INCLUDEALL = <any> 'INCLUDE_ALL',
        EXCLUDESELECTED = <any> 'EXCLUDE_SELECTED',
        INCLUDESELECTED = <any> 'INCLUDE_SELECTED'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum DeviceListTypeEnum {
        INCLUDEALL = <any> 'INCLUDE_ALL',
        EXCLUDESELECTED = <any> 'EXCLUDE_SELECTED',
        INCLUDESELECTED = <any> 'INCLUDE_SELECTED'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum DeviceListEnum {
        UNKNOWN = <any> 'UNKNOWN',
        MOBILETABLET = <any> 'MOBILE_TABLET',
        PC = <any> 'PC',
        CONNECTEDTV = <any> 'CONNECTED_TV',
        PHONE = <any> 'PHONE',
        TABLET = <any> 'TABLET',
        CONNECTEDDEVICE = <any> 'CONNECTED_DEVICE',
        SETTOPBOX = <any> 'SET_TOP_BOX'
    }
}
/**
 * 
 * @export
 * @interface FilterEntry
 */
export interface FilterEntry {
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterEntry
     */
    keys?: Array<FilterEntry.KeysEnum>;
    /**
     * 
     * @type {StatsFilter}
     * @memberof FilterEntry
     */
    filter?: StatsFilter;
}

/**
 * @export
 * @namespace FilterEntry
 */
export namespace FilterEntry {
    /**
     * @export
     * @enum {string}
     */
    export enum KeysEnum {
        AdUnit = <any> 'ad_unit',
        AdUnitName = <any> 'ad_unitName',
        Publisher = <any> 'publisher',
        PublisherName = <any> 'publisherName',
        Site = <any> 'site',
        SiteName = <any> 'siteName',
        DealPublicId = <any> 'dealPublicId',
        Dsp = <any> 'dsp',
        DspNoBidReason = <any> 'dspNoBidReason',
        DspErrorReason = <any> 'dspErrorReason',
        DspTaxonomyVersion = <any> 'dspTaxonomyVersion',
        DspCategories = <any> 'dspCategories',
        SiteTaxonomyVersion = <any> 'siteTaxonomyVersion',
        SiteCategories = <any> 'siteCategories',
        DspName = <any> 'dspName',
        AdSize = <any> 'adSize',
        DirectAd = <any> 'directAd',
        DirectAdName = <any> 'directAdName',
        DirectCampaign = <any> 'directCampaign',
        DirectCampaignName = <any> 'directCampaignName',
        Day = <any> 'day',
        Hour = <any> 'hour',
        Device = <any> 'device',
        Country = <any> 'country',
        CountrySubdivision = <any> 'countrySubdivision',
        Domain = <any> 'domain',
        AdvertiserDomains = <any> 'advertiserDomains',
        Os = <any> 'os',
        Browser = <any> 'browser',
        ReqType = <any> 'reqType',
        AdUnitType = <any> 'adUnitType',
        CreativeType = <any> 'creativeType',
        VideoType = <any> 'videoType',
        PublisherUserIdType = <any> 'publisherUserIdType',
        DspUserIdType = <any> 'dspUserIdType',
        EventErrorReason = <any> 'eventErrorReason',
        ErrorStatus = <any> 'errorStatus'
    }
}
/**
 * 
 * @export
 * @interface IabCategoryBean
 */
export interface IabCategoryBean {
    /**
     * 
     * @type {number}
     * @memberof IabCategoryBean
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof IabCategoryBean
     */
    parentId?: number;
    /**
     * 
     * @type {string}
     * @memberof IabCategoryBean
     */
    iabId?: string;
    /**
     * 
     * @type {string}
     * @memberof IabCategoryBean
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface IdentifierBean
 */
export interface IdentifierBean {
    /**
     * 
     * @type {string}
     * @memberof IdentifierBean
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentifierBean
     */
    value?: string;
}
/**
 * 
 * @export
 * @interface ImageBean
 */
export interface ImageBean {
    /**
     * 
     * @type {number}
     * @memberof ImageBean
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ImageBean
     */
    imageUrl?: string;
}
/**
 * 
 * @export
 * @interface ListResponseAccountBeanInList
 */
export interface ListResponseAccountBeanInList {
    /**
     * 
     * @type {Array<AccountBeanInList>}
     * @memberof ListResponseAccountBeanInList
     */
    content?: Array<AccountBeanInList>;
    /**
     * 
     * @type {number}
     * @memberof ListResponseAccountBeanInList
     */
    totalElements?: number;
}
/**
 * 
 * @export
 * @interface ListResponseAdSizeBean
 */
export interface ListResponseAdSizeBean {
    /**
     * 
     * @type {Array<AdSizeBean>}
     * @memberof ListResponseAdSizeBean
     */
    content?: Array<AdSizeBean>;
    /**
     * 
     * @type {number}
     * @memberof ListResponseAdSizeBean
     */
    totalElements?: number;
}
/**
 * 
 * @export
 * @interface ListResponseAdUnitBean
 */
export interface ListResponseAdUnitBean {
    /**
     * 
     * @type {Array<AdUnitBean>}
     * @memberof ListResponseAdUnitBean
     */
    content?: Array<AdUnitBean>;
    /**
     * 
     * @type {number}
     * @memberof ListResponseAdUnitBean
     */
    totalElements?: number;
}
/**
 * 
 * @export
 * @interface ListResponseAdUnitBeanInList
 */
export interface ListResponseAdUnitBeanInList {
    /**
     * 
     * @type {Array<AdUnitBeanInList>}
     * @memberof ListResponseAdUnitBeanInList
     */
    content?: Array<AdUnitBeanInList>;
    /**
     * 
     * @type {number}
     * @memberof ListResponseAdUnitBeanInList
     */
    totalElements?: number;
}
/**
 * 
 * @export
 * @interface ListResponseDealBeanInList
 */
export interface ListResponseDealBeanInList {
    /**
     * 
     * @type {Array<DealBeanInList>}
     * @memberof ListResponseDealBeanInList
     */
    content?: Array<DealBeanInList>;
    /**
     * 
     * @type {number}
     * @memberof ListResponseDealBeanInList
     */
    totalElements?: number;
}
/**
 * 
 * @export
 * @interface ListResponseDirectAdBeanInList
 */
export interface ListResponseDirectAdBeanInList {
    /**
     * 
     * @type {Array<DirectAdBeanInList>}
     * @memberof ListResponseDirectAdBeanInList
     */
    content?: Array<DirectAdBeanInList>;
    /**
     * 
     * @type {number}
     * @memberof ListResponseDirectAdBeanInList
     */
    totalElements?: number;
}
/**
 * 
 * @export
 * @interface ListResponseDirectCampaignBeanInList
 */
export interface ListResponseDirectCampaignBeanInList {
    /**
     * 
     * @type {Array<DirectCampaignBeanInList>}
     * @memberof ListResponseDirectCampaignBeanInList
     */
    content?: Array<DirectCampaignBeanInList>;
    /**
     * 
     * @type {number}
     * @memberof ListResponseDirectCampaignBeanInList
     */
    totalElements?: number;
}
/**
 * 
 * @export
 * @interface ListResponseDspBeanInList
 */
export interface ListResponseDspBeanInList {
    /**
     * 
     * @type {Array<DspBeanInList>}
     * @memberof ListResponseDspBeanInList
     */
    content?: Array<DspBeanInList>;
    /**
     * 
     * @type {number}
     * @memberof ListResponseDspBeanInList
     */
    totalElements?: number;
}
/**
 * 
 * @export
 * @interface ListResponseFilterBean
 */
export interface ListResponseFilterBean {
    /**
     * 
     * @type {Array<FilterBean>}
     * @memberof ListResponseFilterBean
     */
    content?: Array<FilterBean>;
    /**
     * 
     * @type {number}
     * @memberof ListResponseFilterBean
     */
    totalElements?: number;
}
/**
 * 
 * @export
 * @interface ListResponsePaymentBeanInList
 */
export interface ListResponsePaymentBeanInList {
    /**
     * 
     * @type {Array<PaymentBeanInList>}
     * @memberof ListResponsePaymentBeanInList
     */
    content?: Array<PaymentBeanInList>;
    /**
     * 
     * @type {number}
     * @memberof ListResponsePaymentBeanInList
     */
    totalElements?: number;
}
/**
 * 
 * @export
 * @interface ListResponsePublisherBeanInList
 */
export interface ListResponsePublisherBeanInList {
    /**
     * 
     * @type {Array<PublisherBeanInList>}
     * @memberof ListResponsePublisherBeanInList
     */
    content?: Array<PublisherBeanInList>;
    /**
     * 
     * @type {number}
     * @memberof ListResponsePublisherBeanInList
     */
    totalElements?: number;
}
/**
 * 
 * @export
 * @interface ListResponseSavedReportBean
 */
export interface ListResponseSavedReportBean {
    /**
     * 
     * @type {Array<SavedReportBean>}
     * @memberof ListResponseSavedReportBean
     */
    content?: Array<SavedReportBean>;
    /**
     * 
     * @type {number}
     * @memberof ListResponseSavedReportBean
     */
    totalElements?: number;
}
/**
 * 
 * @export
 * @interface ListResponseSavedReportBeanInList
 */
export interface ListResponseSavedReportBeanInList {
    /**
     * 
     * @type {Array<SavedReportBeanInList>}
     * @memberof ListResponseSavedReportBeanInList
     */
    content?: Array<SavedReportBeanInList>;
    /**
     * 
     * @type {number}
     * @memberof ListResponseSavedReportBeanInList
     */
    totalElements?: number;
}
/**
 * 
 * @export
 * @interface ListResponseSimpleReportBean
 */
export interface ListResponseSimpleReportBean {
    /**
     * 
     * @type {Array<SimpleReportBean>}
     * @memberof ListResponseSimpleReportBean
     */
    content?: Array<SimpleReportBean>;
    /**
     * 
     * @type {number}
     * @memberof ListResponseSimpleReportBean
     */
    totalElements?: number;
}
/**
 * 
 * @export
 * @interface ListResponseSiteBeanInList
 */
export interface ListResponseSiteBeanInList {
    /**
     * 
     * @type {Array<SiteBeanInList>}
     * @memberof ListResponseSiteBeanInList
     */
    content?: Array<SiteBeanInList>;
    /**
     * 
     * @type {number}
     * @memberof ListResponseSiteBeanInList
     */
    totalElements?: number;
}
/**
 * 
 * @export
 * @interface NamedBean
 */
export interface NamedBean {
    /**
     * 
     * @type {number}
     * @memberof NamedBean
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof NamedBean
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface OsBean
 */
export interface OsBean {
    /**
     * 
     * @type {string}
     * @memberof OsBean
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface PaymentAdjustmentBean
 */
export interface PaymentAdjustmentBean {
    /**
     * 
     * @type {number}
     * @memberof PaymentAdjustmentBean
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentAdjustmentBean
     */
    dspId?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentAdjustmentBean
     */
    amountUSD: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentAdjustmentBean
     */
    reason: PaymentAdjustmentBean.ReasonEnum;
}

/**
 * @export
 * @namespace PaymentAdjustmentBean
 */
export namespace PaymentAdjustmentBean {
    /**
     * @export
     * @enum {string}
     */
    export enum ReasonEnum {
        INVALIDTRAFFIC = <any> 'INVALID_TRAFFIC',
        DISCREPANCY = <any> 'DISCREPANCY',
        OTHER = <any> 'OTHER'
    }
}
/**
 * 
 * @export
 * @interface PaymentAdjustmentBeanInList
 */
export interface PaymentAdjustmentBeanInList {
    /**
     * 
     * @type {number}
     * @memberof PaymentAdjustmentBeanInList
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentAdjustmentBeanInList
     */
    dspId?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentAdjustmentBeanInList
     */
    dspName?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentAdjustmentBeanInList
     */
    amountUSD?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentAdjustmentBeanInList
     */
    reason?: PaymentAdjustmentBeanInList.ReasonEnum;
}

/**
 * @export
 * @namespace PaymentAdjustmentBeanInList
 */
export namespace PaymentAdjustmentBeanInList {
    /**
     * @export
     * @enum {string}
     */
    export enum ReasonEnum {
        INVALIDTRAFFIC = <any> 'INVALID_TRAFFIC',
        DISCREPANCY = <any> 'DISCREPANCY',
        OTHER = <any> 'OTHER'
    }
}
/**
 * 
 * @export
 * @interface PaymentBean
 */
export interface PaymentBean {
    /**
     * 
     * @type {number}
     * @memberof PaymentBean
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentBean
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentBean
     */
    modified?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentBean
     */
    modifiedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentBean
     */
    period: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentBean
     */
    status: PaymentBean.StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PaymentBean
     */
    paidDate?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentBean
     */
    sellerId: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentBean
     */
    rateKRW?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentBean
     */
    calculatedCostUSD?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentBean
     */
    calculatedCostKRW?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentBean
     */
    finalCostUSD?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentBean
     */
    finalCostKRW?: number;
    /**
     * 
     * @type {Array<PaymentAdjustmentBean>}
     * @memberof PaymentBean
     */
    adjustments?: Array<PaymentAdjustmentBean>;
}

/**
 * @export
 * @namespace PaymentBean
 */
export namespace PaymentBean {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        DRAFT = <any> 'DRAFT',
        PENDING = <any> 'PENDING',
        PAID = <any> 'PAID'
    }
}
/**
 * 
 * @export
 * @interface PaymentBeanInList
 */
export interface PaymentBeanInList {
    /**
     * 
     * @type {number}
     * @memberof PaymentBeanInList
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentBeanInList
     */
    sellerId?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentBeanInList
     */
    sellerName?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentBeanInList
     */
    period?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentBeanInList
     */
    status?: PaymentBeanInList.StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PaymentBeanInList
     */
    paidDate?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentBeanInList
     */
    rateKRW?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentBeanInList
     */
    calculatedCostUSD?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentBeanInList
     */
    calculatedCostKRW?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentBeanInList
     */
    finalCostUSD?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentBeanInList
     */
    finalCostKRW?: number;
    /**
     * 
     * @type {Array<PaymentAdjustmentBeanInList>}
     * @memberof PaymentBeanInList
     */
    adjustments?: Array<PaymentAdjustmentBeanInList>;
}

/**
 * @export
 * @namespace PaymentBeanInList
 */
export namespace PaymentBeanInList {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        DRAFT = <any> 'DRAFT',
        PENDING = <any> 'PENDING',
        PAID = <any> 'PAID'
    }
}
/**
 * 
 * @export
 * @interface PublisherBean
 */
export interface PublisherBean {
    /**
     * 
     * @type {number}
     * @memberof PublisherBean
     */
    id?: number;
    /**
     * Publisher name
     * @type {string}
     * @memberof PublisherBean
     */
    name: string;
    /**
     * Publisher public ID
     * @type {string}
     * @memberof PublisherBean
     */
    publicId?: string;
    /**
     * Publisher website
     * @type {string}
     * @memberof PublisherBean
     */
    website: string;
    /**
     * 
     * @type {string}
     * @memberof PublisherBean
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof PublisherBean
     */
    userCurrency: PublisherBean.UserCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof PublisherBean
     */
    biddingCurrency: PublisherBean.BiddingCurrencyEnum;
    /**
     * Publisher company
     * @type {string}
     * @memberof PublisherBean
     */
    company?: string;
    /**
     * Publisher description
     * @type {string}
     * @memberof PublisherBean
     */
    description?: string;
    /**
     * Revenue share
     * @type {number}
     * @memberof PublisherBean
     */
    revenueShare: number;
    /**
     * 
     * @type {FilterBean}
     * @memberof PublisherBean
     */
    filterBean?: FilterBean;
    /**
     * 
     * @type {string}
     * @memberof PublisherBean
     */
    blockedDomains?: string;
    /**
     * 
     * @type {string}
     * @memberof PublisherBean
     */
    iabTaxonomyVersion?: PublisherBean.IabTaxonomyVersionEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof PublisherBean
     */
    categories?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PublisherBean
     */
    blockedCategories?: Array<number>;
    /**
     * 
     * @type {Array<DspIdMappingBean>}
     * @memberof PublisherBean
     */
    dspIdMappings?: Array<DspIdMappingBean>;
}

/**
 * @export
 * @namespace PublisherBean
 */
export namespace PublisherBean {
    /**
     * @export
     * @enum {string}
     */
    export enum UserCurrencyEnum {
        USD = <any> 'USD',
        KRW = <any> 'KRW'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum BiddingCurrencyEnum {
        USD = <any> 'USD',
        KRW = <any> 'KRW'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum IabTaxonomyVersionEnum {
        _10 = <any> 'VERSION_10',
        _22 = <any> 'VERSION_22'
    }
}
/**
 * 
 * @export
 * @interface PublisherBeanInList
 */
export interface PublisherBeanInList {
    /**
     * 
     * @type {number}
     * @memberof PublisherBeanInList
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PublisherBeanInList
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PublisherBeanInList
     */
    website?: string;
    /**
     * 
     * @type {string}
     * @memberof PublisherBeanInList
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof PublisherBeanInList
     */
    userCurrency?: PublisherBeanInList.UserCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof PublisherBeanInList
     */
    biddingCurrency?: PublisherBeanInList.BiddingCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof PublisherBeanInList
     */
    company?: string;
    /**
     * 
     * @type {string}
     * @memberof PublisherBeanInList
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof PublisherBeanInList
     */
    blockedDomains?: string;
    /**
     * 
     * @type {number}
     * @memberof PublisherBeanInList
     */
    revenueShare?: number;
    /**
     * 
     * @type {FilterBean}
     * @memberof PublisherBeanInList
     */
    filterBean?: FilterBean;
    /**
     * 
     * @type {number}
     * @memberof PublisherBeanInList
     */
    siteCount?: number;
    /**
     * 
     * @type {number}
     * @memberof PublisherBeanInList
     */
    adUnitCount?: number;
    /**
     * 
     * @type {string}
     * @memberof PublisherBeanInList
     */
    iabTaxonomyVersion?: PublisherBeanInList.IabTaxonomyVersionEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof PublisherBeanInList
     */
    categories?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PublisherBeanInList
     */
    blockedCategories?: Array<string>;
    /**
     * 
     * @type {Array<DspIdMappingBean>}
     * @memberof PublisherBeanInList
     */
    dspIdMappingBeans?: Array<DspIdMappingBean>;
}

/**
 * @export
 * @namespace PublisherBeanInList
 */
export namespace PublisherBeanInList {
    /**
     * @export
     * @enum {string}
     */
    export enum UserCurrencyEnum {
        USD = <any> 'USD',
        KRW = <any> 'KRW'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum BiddingCurrencyEnum {
        USD = <any> 'USD',
        KRW = <any> 'KRW'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum IabTaxonomyVersionEnum {
        _10 = <any> 'VERSION_10',
        _22 = <any> 'VERSION_22'
    }
}
/**
 * 
 * @export
 * @interface RawStatRow
 */
export interface RawStatRow {
    /**
     * 
     * @type {string}
     * @memberof RawStatRow
     */
    day?: string;
    /**
     * 
     * @type {string}
     * @memberof RawStatRow
     */
    hour?: string;
    /**
     * 
     * @type {string}
     * @memberof RawStatRow
     */
    dealPublicId?: string;
    /**
     * 
     * @type {string}
     * @memberof RawStatRow
     */
    adSize?: string;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    site?: number;
    /**
     * 
     * @type {string}
     * @memberof RawStatRow
     */
    siteName?: string;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    directAd?: number;
    /**
     * 
     * @type {string}
     * @memberof RawStatRow
     */
    directAdName?: string;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    directCampaign?: number;
    /**
     * 
     * @type {string}
     * @memberof RawStatRow
     */
    directCampaignName?: string;
    /**
     * 
     * @type {string}
     * @memberof RawStatRow
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof RawStatRow
     */
    countrySubdivision?: string;
    /**
     * 
     * @type {string}
     * @memberof RawStatRow
     */
    domain?: string;
    /**
     * 
     * @type {string}
     * @memberof RawStatRow
     */
    advertiserDomains?: string;
    /**
     * 
     * @type {string}
     * @memberof RawStatRow
     */
    device?: string;
    /**
     * 
     * @type {string}
     * @memberof RawStatRow
     */
    reqType?: string;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    publisher?: number;
    /**
     * 
     * @type {string}
     * @memberof RawStatRow
     */
    publisherName?: string;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    pubReq?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    pubBidFloor?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    pubBidFloorAvg?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    pubBid?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    pubBidPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    pubBidPriceAvg?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    pubBidRate?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    pubWin?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    pubWinRate?: number;
    /**
     * 
     * @type {string}
     * @memberof RawStatRow
     */
    siteTaxonomyVersion?: string;
    /**
     * 
     * @type {string}
     * @memberof RawStatRow
     */
    siteCategories?: string;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    dsp?: number;
    /**
     * 
     * @type {string}
     * @memberof RawStatRow
     */
    dspName?: string;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    dspReq?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    dspBid?: number;
    /**
     * 
     * @type {string}
     * @memberof RawStatRow
     */
    dspNoBidReason?: string;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    dspBidPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    dspBidPriceAvg?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    dspBidRate?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    dspError?: number;
    /**
     * 
     * @type {string}
     * @memberof RawStatRow
     */
    dspErrorReason?: string;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    dspWinRate?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    dspWinPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    dspWinPriceAvg?: number;
    /**
     * 
     * @type {string}
     * @memberof RawStatRow
     */
    dspTaxonomyVersion?: string;
    /**
     * 
     * @type {string}
     * @memberof RawStatRow
     */
    dspCategories?: string;
    /**
     * 
     * @type {string}
     * @memberof RawStatRow
     */
    errorStatus?: string;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    render?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    impr?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    imprPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    netImprPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    imprPriceAvg?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    netImprPriceAvg?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    profit?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    imprRate?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    dspClick?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    directAdClick?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    directAdCtr?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    vastClick?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    vastCtr?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    vastError?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    vastStart?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    vastFirstQ?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    vastMid?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    vastThirdQ?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    vastComplete?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    vastCloseLinear?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    vastSkip?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    vastPause?: number;
    /**
     * 
     * @type {string}
     * @memberof RawStatRow
     */
    os?: string;
    /**
     * 
     * @type {string}
     * @memberof RawStatRow
     */
    browser?: string;
    /**
     * 
     * @type {string}
     * @memberof RawStatRow
     */
    adUnitType?: string;
    /**
     * 
     * @type {string}
     * @memberof RawStatRow
     */
    creativeType?: string;
    /**
     * 
     * @type {string}
     * @memberof RawStatRow
     */
    videoType?: string;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    dspCtr?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    fillRate?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    interstitial?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    rewarded?: number;
    /**
     * 
     * @type {string}
     * @memberof RawStatRow
     */
    publisherUserIdType?: string;
    /**
     * 
     * @type {string}
     * @memberof RawStatRow
     */
    dspUserIdType?: string;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    winError?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    renderError?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    imprError?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    clickError?: number;
    /**
     * 
     * @type {string}
     * @memberof RawStatRow
     */
    eventErrorReason?: string;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    externalImpr?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    externalRevenue?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    vastRetrieve?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    playerLoading?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    videoJsLoaded?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    pluginsLoaded?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    playerError?: number;
    /**
     * 
     * @type {number}
     * @memberof RawStatRow
     */
    adUnit?: number;
    /**
     * 
     * @type {string}
     * @memberof RawStatRow
     */
    adUnitName?: string;
}
/**
 * 
 * @export
 * @interface SavedReportBean
 */
export interface SavedReportBean {
    /**
     * 
     * @type {number}
     * @memberof SavedReportBean
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SavedReportBean
     */
    favorite: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SavedReportBean
     */
    shared: boolean;
    /**
     * 
     * @type {string}
     * @memberof SavedReportBean
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SavedReportBean
     */
    statRequestBeanJson: string;
}
/**
 * 
 * @export
 * @interface SavedReportBeanInList
 */
export interface SavedReportBeanInList {
    /**
     * 
     * @type {number}
     * @memberof SavedReportBeanInList
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SavedReportBeanInList
     */
    ownerName?: string;
    /**
     * 
     * @type {string}
     * @memberof SavedReportBeanInList
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SavedReportBeanInList
     */
    favorite?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SavedReportBeanInList
     */
    shared?: boolean;
}
/**
 * 
 * @export
 * @interface ScheduledReportBean
 */
export interface ScheduledReportBean {
    /**
     * 
     * @type {number}
     * @memberof ScheduledReportBean
     */
    savedReportId: number;
    /**
     * 
     * @type {string}
     * @memberof ScheduledReportBean
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduledReportBean
     */
    frequency: ScheduledReportBean.FrequencyEnum;
    /**
     * 
     * @type {number}
     * @memberof ScheduledReportBean
     */
    hour: number;
    /**
     * 
     * @type {string}
     * @memberof ScheduledReportBean
     */
    timeZone: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ScheduledReportBean
     */
    recipients: Array<string>;
}

/**
 * @export
 * @namespace ScheduledReportBean
 */
export namespace ScheduledReportBean {
    /**
     * @export
     * @enum {string}
     */
    export enum FrequencyEnum {
        TEST = <any> 'TEST',
        DAILY = <any> 'DAILY',
        WEEKLY = <any> 'WEEKLY',
        MONTHLY = <any> 'MONTHLY'
    }
}
/**
 * 
 * @export
 * @interface SellerBean
 */
export interface SellerBean {
    /**
     * 
     * @type {string}
     * @memberof SellerBean
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerBean
     */
    domain?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerBean
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerBean
     */
    sellerId?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerBean
     */
    sellerType?: string;
    /**
     * 
     * @type {number}
     * @memberof SellerBean
     */
    isConfidential?: number;
    /**
     * 
     * @type {number}
     * @memberof SellerBean
     */
    isPassthrough?: number;
}
/**
 * 
 * @export
 * @interface SellersBean
 */
export interface SellersBean {
    /**
     * 
     * @type {string}
     * @memberof SellersBean
     */
    version?: string;
    /**
     * 
     * @type {Array<IdentifierBean>}
     * @memberof SellersBean
     */
    identifiers?: Array<IdentifierBean>;
    /**
     * 
     * @type {Array<SellerBean>}
     * @memberof SellersBean
     */
    sellers?: Array<SellerBean>;
    /**
     * 
     * @type {string}
     * @memberof SellersBean
     */
    contactEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof SellersBean
     */
    contactAddress?: string;
}
/**
 * 
 * @export
 * @interface SimpleAdUnitBean
 */
export interface SimpleAdUnitBean {
    /**
     * 
     * @type {number}
     * @memberof SimpleAdUnitBean
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SimpleAdUnitBean
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof SimpleAdUnitBean
     */
    siteId?: number;
}
/**
 * 
 * @export
 * @interface SimpleReportBean
 */
export interface SimpleReportBean {
    /**
     * 
     * @type {number}
     * @memberof SimpleReportBean
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SimpleReportBean
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SimpleReportBean
     */
    shared?: boolean;
}
/**
 * 
 * @export
 * @interface SimpleSiteBean
 */
export interface SimpleSiteBean {
    /**
     * 
     * @type {number}
     * @memberof SimpleSiteBean
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SimpleSiteBean
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof SimpleSiteBean
     */
    publisherId?: number;
    /**
     * 
     * @type {string}
     * @memberof SimpleSiteBean
     */
    domain?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleSiteBean
     */
    biddingCurrency?: SimpleSiteBean.BiddingCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof SimpleSiteBean
     */
    rtbDetailsType?: SimpleSiteBean.RtbDetailsTypeEnum;
}

/**
 * @export
 * @namespace SimpleSiteBean
 */
export namespace SimpleSiteBean {
    /**
     * @export
     * @enum {string}
     */
    export enum BiddingCurrencyEnum {
        USD = <any> 'USD',
        KRW = <any> 'KRW'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum RtbDetailsTypeEnum {
        SITE = <any> 'SITE',
        APP = <any> 'APP'
    }
}
/**
 * 
 * @export
 * @interface SiteBean
 */
export interface SiteBean {
    /**
     * 
     * @type {number}
     * @memberof SiteBean
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SiteBean
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof SiteBean
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SiteBean
     */
    domain: string;
    /**
     * 
     * @type {number}
     * @memberof SiteBean
     */
    publisherId: number;
    /**
     * 
     * @type {FilterBean}
     * @memberof SiteBean
     */
    filterBean?: FilterBean;
    /**
     * 
     * @type {string}
     * @memberof SiteBean
     */
    blockedDomains?: string;
    /**
     * 
     * @type {string}
     * @memberof SiteBean
     */
    userCurrency?: SiteBean.UserCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof SiteBean
     */
    biddingCurrency?: SiteBean.BiddingCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof SiteBean
     */
    rtbDetailsType: SiteBean.RtbDetailsTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SiteBean
     */
    bundle?: string;
    /**
     * 
     * @type {string}
     * @memberof SiteBean
     */
    iabTaxonomyVersion?: SiteBean.IabTaxonomyVersionEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof SiteBean
     */
    categories?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof SiteBean
     */
    blockedCategories?: Array<number>;
    /**
     * 
     * @type {Array<DspIdMappingBean>}
     * @memberof SiteBean
     */
    dspIdMappings?: Array<DspIdMappingBean>;
    /**
     * 
     * @type {number}
     * @memberof SiteBean
     */
    samplingRate?: number;
}

/**
 * @export
 * @namespace SiteBean
 */
export namespace SiteBean {
    /**
     * @export
     * @enum {string}
     */
    export enum UserCurrencyEnum {
        USD = <any> 'USD',
        KRW = <any> 'KRW'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum BiddingCurrencyEnum {
        USD = <any> 'USD',
        KRW = <any> 'KRW'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum RtbDetailsTypeEnum {
        SITE = <any> 'SITE',
        APP = <any> 'APP'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum IabTaxonomyVersionEnum {
        _10 = <any> 'VERSION_10',
        _22 = <any> 'VERSION_22'
    }
}
/**
 * 
 * @export
 * @interface SiteBeanInList
 */
export interface SiteBeanInList {
    /**
     * 
     * @type {number}
     * @memberof SiteBeanInList
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SiteBeanInList
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SiteBeanInList
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SiteBeanInList
     */
    domain?: string;
    /**
     * 
     * @type {number}
     * @memberof SiteBeanInList
     */
    publisherId?: number;
    /**
     * 
     * @type {string}
     * @memberof SiteBeanInList
     */
    userCurrency?: SiteBeanInList.UserCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof SiteBeanInList
     */
    biddingCurrency?: SiteBeanInList.BiddingCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof SiteBeanInList
     */
    rtbDetailsType?: SiteBeanInList.RtbDetailsTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SiteBeanInList
     */
    bundle?: string;
    /**
     * 
     * @type {string}
     * @memberof SiteBeanInList
     */
    blockedDomains?: string;
    /**
     * 
     * @type {FilterBean}
     * @memberof SiteBeanInList
     */
    filterBean?: FilterBean;
    /**
     * 
     * @type {string}
     * @memberof SiteBeanInList
     */
    publisherName?: string;
    /**
     * 
     * @type {number}
     * @memberof SiteBeanInList
     */
    adUnitCount?: number;
    /**
     * 
     * @type {string}
     * @memberof SiteBeanInList
     */
    iabTaxonomyVersion?: SiteBeanInList.IabTaxonomyVersionEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof SiteBeanInList
     */
    categories?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SiteBeanInList
     */
    blockedCategories?: Array<string>;
    /**
     * 
     * @type {Array<DspIdMappingBean>}
     * @memberof SiteBeanInList
     */
    dspIdMappingBeans?: Array<DspIdMappingBean>;
    /**
     * 
     * @type {number}
     * @memberof SiteBeanInList
     */
    samplingRate?: number;
}

/**
 * @export
 * @namespace SiteBeanInList
 */
export namespace SiteBeanInList {
    /**
     * @export
     * @enum {string}
     */
    export enum UserCurrencyEnum {
        USD = <any> 'USD',
        KRW = <any> 'KRW'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum BiddingCurrencyEnum {
        USD = <any> 'USD',
        KRW = <any> 'KRW'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum RtbDetailsTypeEnum {
        SITE = <any> 'SITE',
        APP = <any> 'APP'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum IabTaxonomyVersionEnum {
        _10 = <any> 'VERSION_10',
        _22 = <any> 'VERSION_22'
    }
}
/**
 * 
 * @export
 * @interface StatRequestBean
 */
export interface StatRequestBean {
    /**
     * Columns for select
     * @type {Array<string>}
     * @memberof StatRequestBean
     */
    values: Array<StatRequestBean.ValuesEnum>;
    /**
     * Group by columns
     * @type {Array<string>}
     * @memberof StatRequestBean
     */
    keys: Array<StatRequestBean.KeysEnum>;
    /**
     * 
     * @type {string}
     * @memberof StatRequestBean
     */
    currency: string;
    /**
     * 
     * @type {boolean}
     * @memberof StatRequestBean
     */
    audit: boolean;
    /**
     * 
     * @type {string}
     * @memberof StatRequestBean
     */
    periodType: StatRequestBean.PeriodTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof StatRequestBean
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof StatRequestBean
     */
    endDate?: string;
    /**
     * 
     * @type {string}
     * @memberof StatRequestBean
     */
    timeZone: string;
    /**
     * 
     * @type {Array<FilterEntry>}
     * @memberof StatRequestBean
     */
    filters?: Array<FilterEntry>;
    /**
     * 
     * @type {string}
     * @memberof StatRequestBean
     */
    orderBy?: string;
    /**
     * 
     * @type {string}
     * @memberof StatRequestBean
     */
    order?: StatRequestBean.OrderEnum;
    /**
     * 
     * @type {number}
     * @memberof StatRequestBean
     */
    limit?: number;
}

/**
 * @export
 * @namespace StatRequestBean
 */
export namespace StatRequestBean {
    /**
     * @export
     * @enum {string}
     */
    export enum ValuesEnum {
        PubReq = <any> 'pubReq',
        PubBid = <any> 'pubBid',
        PubBidFloor = <any> 'pubBidFloor',
        PubBidFloorAvg = <any> 'pubBidFloorAvg',
        PubBidPrice = <any> 'pubBidPrice',
        PubBidPriceAvg = <any> 'pubBidPriceAvg',
        PubBidRate = <any> 'pubBidRate',
        PubWin = <any> 'pubWin',
        PubWinRate = <any> 'pubWinRate',
        DspReq = <any> 'dspReq',
        DspBid = <any> 'dspBid',
        DspBidPrice = <any> 'dspBidPrice',
        DspBidPriceAvg = <any> 'dspBidPriceAvg',
        DspBidRate = <any> 'dspBidRate',
        DspError = <any> 'dspError',
        DspWinRate = <any> 'dspWinRate',
        DspWinPrice = <any> 'dspWinPrice',
        DspWinPriceAvg = <any> 'dspWinPriceAvg',
        DspTaxonomyVersion = <any> 'dspTaxonomyVersion',
        Render = <any> 'render',
        Impr = <any> 'impr',
        ImprPrice = <any> 'imprPrice',
        NetImprPrice = <any> 'netImprPrice',
        ImprPriceAvg = <any> 'imprPriceAvg',
        NetImprPriceAvg = <any> 'netImprPriceAvg',
        Profit = <any> 'profit',
        ImprRate = <any> 'imprRate',
        FillRate = <any> 'fillRate',
        DspClick = <any> 'dspClick',
        DspCtr = <any> 'dspCtr',
        DirectAdClick = <any> 'directAdClick',
        DirectAdCtr = <any> 'directAdCtr',
        VastClick = <any> 'vastClick',
        VastCtr = <any> 'vastCtr',
        VastError = <any> 'vastError',
        VastStart = <any> 'vastStart',
        VastFirstQ = <any> 'vastFirstQ',
        VastMid = <any> 'vastMid',
        VastThirdQ = <any> 'vastThirdQ',
        VastComplete = <any> 'vastComplete',
        VastCloseLinear = <any> 'vastCloseLinear',
        VastSkip = <any> 'vastSkip',
        VastPause = <any> 'vastPause',
        Interstitial = <any> 'interstitial',
        Rewarded = <any> 'rewarded',
        WinError = <any> 'winError',
        RenderError = <any> 'renderError',
        ImprError = <any> 'imprError',
        ClickError = <any> 'clickError',
        ExternalImpr = <any> 'externalImpr',
        ExternalRevenue = <any> 'externalRevenue',
        VastRetrieve = <any> 'vastRetrieve',
        PlayerLoading = <any> 'playerLoading',
        VideoJsLoaded = <any> 'videoJsLoaded',
        PluginsLoaded = <any> 'pluginsLoaded',
        PlayerError = <any> 'playerError'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum KeysEnum {
        AdUnit = <any> 'ad_unit',
        AdUnitName = <any> 'ad_unitName',
        Publisher = <any> 'publisher',
        PublisherName = <any> 'publisherName',
        Site = <any> 'site',
        SiteName = <any> 'siteName',
        DealPublicId = <any> 'dealPublicId',
        Dsp = <any> 'dsp',
        DspNoBidReason = <any> 'dspNoBidReason',
        DspErrorReason = <any> 'dspErrorReason',
        DspTaxonomyVersion = <any> 'dspTaxonomyVersion',
        DspCategories = <any> 'dspCategories',
        SiteTaxonomyVersion = <any> 'siteTaxonomyVersion',
        SiteCategories = <any> 'siteCategories',
        DspName = <any> 'dspName',
        AdSize = <any> 'adSize',
        DirectAd = <any> 'directAd',
        DirectAdName = <any> 'directAdName',
        DirectCampaign = <any> 'directCampaign',
        DirectCampaignName = <any> 'directCampaignName',
        Day = <any> 'day',
        Hour = <any> 'hour',
        Device = <any> 'device',
        Country = <any> 'country',
        CountrySubdivision = <any> 'countrySubdivision',
        Domain = <any> 'domain',
        AdvertiserDomains = <any> 'advertiserDomains',
        Os = <any> 'os',
        Browser = <any> 'browser',
        ReqType = <any> 'reqType',
        AdUnitType = <any> 'adUnitType',
        CreativeType = <any> 'creativeType',
        VideoType = <any> 'videoType',
        PublisherUserIdType = <any> 'publisherUserIdType',
        DspUserIdType = <any> 'dspUserIdType',
        EventErrorReason = <any> 'eventErrorReason',
        ErrorStatus = <any> 'errorStatus'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum PeriodTypeEnum {
        TODAY = <any> 'TODAY',
        YESTERDAY = <any> 'YESTERDAY',
        LAST3DAYS = <any> 'LAST_3_DAYS',
        LAST7DAYS = <any> 'LAST_7_DAYS',
        LAST30DAYS = <any> 'LAST_30_DAYS',
        THISMONTH = <any> 'THIS_MONTH',
        LASTMONTH = <any> 'LAST_MONTH',
        CUSTOMRANGE = <any> 'CUSTOM_RANGE'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum OrderEnum {
        Asc = <any> 'asc',
        Desc = <any> 'desc'
    }
}
/**
 * 
 * @export
 * @interface StatResponseBeanRawStatRow
 */
export interface StatResponseBeanRawStatRow {
    /**
     * 
     * @type {Array<RawStatRow>}
     * @memberof StatResponseBeanRawStatRow
     */
    rows?: Array<RawStatRow>;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof StatResponseBeanRawStatRow
     */
    total?: { [key: string]: number; };
    /**
     * 
     * @type {number}
     * @memberof StatResponseBeanRawStatRow
     */
    lastUpdate?: number;
}
/**
 * 
 * @export
 * @interface StatsFilter
 */
export interface StatsFilter {
    /**
     * 
     * @type {string}
     * @memberof StatsFilter
     */
    type: StatsFilter.TypeEnum;
    /**
     * 
     * @type {Array<any>}
     * @memberof StatsFilter
     */
    params: Array<any>;
}

/**
 * @export
 * @namespace StatsFilter
 */
export namespace StatsFilter {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        INLIST = <any> 'IN_LIST',
        RANGE = <any> 'RANGE',
        EQUAL = <any> 'EQUAL',
        HOUR = <any> 'HOUR',
        INTERSECTIONWITHLIST = <any> 'INTERSECTION_WITH_LIST'
    }
}
/**
 * 
 * @export
 * @interface TypedDspIdMappingBean
 */
export interface TypedDspIdMappingBean {
    /**
     * 
     * @type {number}
     * @memberof TypedDspIdMappingBean
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof TypedDspIdMappingBean
     */
    entityId?: number;
    /**
     * 
     * @type {number}
     * @memberof TypedDspIdMappingBean
     */
    dspId?: number;
    /**
     * 
     * @type {string}
     * @memberof TypedDspIdMappingBean
     */
    entityIdInDsp?: string;
    /**
     * 
     * @type {string}
     * @memberof TypedDspIdMappingBean
     */
    dspIdMappingCreativeType?: TypedDspIdMappingBean.DspIdMappingCreativeTypeEnum;
}

/**
 * @export
 * @namespace TypedDspIdMappingBean
 */
export namespace TypedDspIdMappingBean {
    /**
     * @export
     * @enum {string}
     */
    export enum DspIdMappingCreativeTypeEnum {
        ALL = <any> 'ALL',
        VIDEO = <any> 'VIDEO',
        BANNER = <any> 'BANNER',
        NATIVE = <any> 'NATIVE'
    }
}
/**
 * AccountControllerApi - fetch parameter creator
 * @export
 */
export const AccountControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get Account by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        account(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling account.');
            }
            const localVarPath = `/api/account/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Account by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountForList(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling accountForList.');
            }
            const localVarPath = `/api/account/list/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all Accounts
         * @param {number} [size] Number of records per page.
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountList(size?: number, page?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/account`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create new Account
         * @param {AccountBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccount(body: AccountBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createAccount.');
            }
            const localVarPath = `/api/account`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete Account by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccount(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteAccount.');
            }
            const localVarPath = `/api/account/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update Account by id
         * @param {AccountBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccount(body: AccountBean, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateAccount.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateAccount.');
            }
            const localVarPath = `/api/account/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountControllerApi - functional programming interface
 * @export
 */
export const AccountControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Get Account by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        account(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountBean> {
            const localVarFetchArgs = AccountControllerApiFetchParamCreator(configuration).account(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Get Account by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountForList(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountBeanInList> {
            const localVarFetchArgs = AccountControllerApiFetchParamCreator(configuration).accountForList(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Get all Accounts
         * @param {number} [size] Number of records per page.
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountList(size?: number, page?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponseAccountBeanInList> {
            const localVarFetchArgs = AccountControllerApiFetchParamCreator(configuration).accountList(size, page, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Create new Account
         * @param {AccountBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccount(body: AccountBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountBean> {
            const localVarFetchArgs = AccountControllerApiFetchParamCreator(configuration).createAccount(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Delete Account by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccount(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AccountControllerApiFetchParamCreator(configuration).deleteAccount(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Update Account by id
         * @param {AccountBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccount(body: AccountBean, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountBean> {
            const localVarFetchArgs = AccountControllerApiFetchParamCreator(configuration).updateAccount(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AccountControllerApi - factory interface
 * @export
 */
export const AccountControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Get Account by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        account(id: number, options?: any) {
            return AccountControllerApiFp(configuration).account(id, options)(fetch, basePath);
        },
        /**
         * Get Account by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountForList(id: number, options?: any) {
            return AccountControllerApiFp(configuration).accountForList(id, options)(fetch, basePath);
        },
        /**
         * Get all Accounts
         * @param {number} [size] Number of records per page.
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountList(size?: number, page?: number, options?: any) {
            return AccountControllerApiFp(configuration).accountList(size, page, options)(fetch, basePath);
        },
        /**
         * Create new Account
         * @param {AccountBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccount(body: AccountBean, options?: any) {
            return AccountControllerApiFp(configuration).createAccount(body, options)(fetch, basePath);
        },
        /**
         * Delete Account by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccount(id: number, options?: any) {
            return AccountControllerApiFp(configuration).deleteAccount(id, options)(fetch, basePath);
        },
        /**
         * Update Account by id
         * @param {AccountBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccount(body: AccountBean, id: number, options?: any) {
            return AccountControllerApiFp(configuration).updateAccount(body, id, options)(fetch, basePath);
        },
    };
};

/**
 * AccountControllerApi - object-oriented interface
 * @export
 * @class AccountControllerApi
 * @extends {BaseAPI}
 */
export class AccountControllerApi extends BaseAPI {
    /**
     * Get Account by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public account(id: number, options?: any) {
        return AccountControllerApiFp(this.configuration).account(id, options)(this.fetch, this.basePath);
    }

    /**
     * Get Account by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public accountForList(id: number, options?: any) {
        return AccountControllerApiFp(this.configuration).accountForList(id, options)(this.fetch, this.basePath);
    }

    /**
     * Get all Accounts
     * @param {number} [size] Number of records per page.
     * @param {number} [page] Results page you want to retrieve (0..N)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public accountList(size?: number, page?: number, options?: any) {
        return AccountControllerApiFp(this.configuration).accountList(size, page, options)(this.fetch, this.basePath);
    }

    /**
     * Create new Account
     * @param {AccountBean} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public createAccount(body: AccountBean, options?: any) {
        return AccountControllerApiFp(this.configuration).createAccount(body, options)(this.fetch, this.basePath);
    }

    /**
     * Delete Account by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public deleteAccount(id: number, options?: any) {
        return AccountControllerApiFp(this.configuration).deleteAccount(id, options)(this.fetch, this.basePath);
    }

    /**
     * Update Account by id
     * @param {AccountBean} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public updateAccount(body: AccountBean, id: number, options?: any) {
        return AccountControllerApiFp(this.configuration).updateAccount(body, id, options)(this.fetch, this.basePath);
    }

}
/**
 * AdSizeControllerApi - fetch parameter creator
 * @export
 */
export const AdSizeControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adSize(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling adSize.');
            }
            const localVarPath = `/api/ad-size/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {string} [name] 
         * @param {boolean} [active] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adSizeFilteredList(id?: number, name?: string, active?: boolean, options: any = {}): FetchArgs {
            const localVarPath = `/api/ad-size/filtered`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (active !== undefined) {
                localVarQueryParameter['active'] = active;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adSizeList(options: any = {}): FetchArgs {
            const localVarPath = `/api/ad-size`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adSizeListAll(options: any = {}): FetchArgs {
            const localVarPath = `/api/ad-size/all`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} adUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adSizeListByAdUnitId(adUnitId: number, options: any = {}): FetchArgs {
            // verify required parameter 'adUnitId' is not null or undefined
            if (adUnitId === null || adUnitId === undefined) {
                throw new RequiredError('adUnitId','Required parameter adUnitId was null or undefined when calling adSizeListByAdUnitId.');
            }
            const localVarPath = `/api/ad-size/by-ad-unit-{adUnitId}`
                .replace(`{${"adUnitId"}}`, encodeURIComponent(String(adUnitId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdSizeBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdSize(body: AdSizeBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createAdSize.');
            }
            const localVarPath = `/api/ad-size`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AdSizeBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdSize(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteAdSize.');
            }
            const localVarPath = `/api/ad-size/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdSizeBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdSize(body: AdSizeBean, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateAdSize.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateAdSize.');
            }
            const localVarPath = `/api/ad-size/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AdSizeBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdSizeControllerApi - functional programming interface
 * @export
 */
export const AdSizeControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adSize(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdSizeBean> {
            const localVarFetchArgs = AdSizeControllerApiFetchParamCreator(configuration).adSize(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {string} [name] 
         * @param {boolean} [active] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adSizeFilteredList(id?: number, name?: string, active?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponseAdSizeBean> {
            const localVarFetchArgs = AdSizeControllerApiFetchParamCreator(configuration).adSizeFilteredList(id, name, active, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adSizeList(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponseAdSizeBean> {
            const localVarFetchArgs = AdSizeControllerApiFetchParamCreator(configuration).adSizeList(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adSizeListAll(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<NamedBean>> {
            const localVarFetchArgs = AdSizeControllerApiFetchParamCreator(configuration).adSizeListAll(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} adUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adSizeListByAdUnitId(adUnitId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<AdSizeBean>> {
            const localVarFetchArgs = AdSizeControllerApiFetchParamCreator(configuration).adSizeListByAdUnitId(adUnitId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AdSizeBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdSize(body: AdSizeBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdSizeBean> {
            const localVarFetchArgs = AdSizeControllerApiFetchParamCreator(configuration).createAdSize(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdSize(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdSizeControllerApiFetchParamCreator(configuration).deleteAdSize(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AdSizeBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdSize(body: AdSizeBean, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdSizeBean> {
            const localVarFetchArgs = AdSizeControllerApiFetchParamCreator(configuration).updateAdSize(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AdSizeControllerApi - factory interface
 * @export
 */
export const AdSizeControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adSize(id: number, options?: any) {
            return AdSizeControllerApiFp(configuration).adSize(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {string} [name] 
         * @param {boolean} [active] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adSizeFilteredList(id?: number, name?: string, active?: boolean, options?: any) {
            return AdSizeControllerApiFp(configuration).adSizeFilteredList(id, name, active, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adSizeList(options?: any) {
            return AdSizeControllerApiFp(configuration).adSizeList(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adSizeListAll(options?: any) {
            return AdSizeControllerApiFp(configuration).adSizeListAll(options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} adUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adSizeListByAdUnitId(adUnitId: number, options?: any) {
            return AdSizeControllerApiFp(configuration).adSizeListByAdUnitId(adUnitId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {AdSizeBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdSize(body: AdSizeBean, options?: any) {
            return AdSizeControllerApiFp(configuration).createAdSize(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdSize(id: number, options?: any) {
            return AdSizeControllerApiFp(configuration).deleteAdSize(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {AdSizeBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdSize(body: AdSizeBean, id: number, options?: any) {
            return AdSizeControllerApiFp(configuration).updateAdSize(body, id, options)(fetch, basePath);
        },
    };
};

/**
 * AdSizeControllerApi - object-oriented interface
 * @export
 * @class AdSizeControllerApi
 * @extends {BaseAPI}
 */
export class AdSizeControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdSizeControllerApi
     */
    public adSize(id: number, options?: any) {
        return AdSizeControllerApiFp(this.configuration).adSize(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [id] 
     * @param {string} [name] 
     * @param {boolean} [active] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdSizeControllerApi
     */
    public adSizeFilteredList(id?: number, name?: string, active?: boolean, options?: any) {
        return AdSizeControllerApiFp(this.configuration).adSizeFilteredList(id, name, active, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdSizeControllerApi
     */
    public adSizeList(options?: any) {
        return AdSizeControllerApiFp(this.configuration).adSizeList(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdSizeControllerApi
     */
    public adSizeListAll(options?: any) {
        return AdSizeControllerApiFp(this.configuration).adSizeListAll(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} adUnitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdSizeControllerApi
     */
    public adSizeListByAdUnitId(adUnitId: number, options?: any) {
        return AdSizeControllerApiFp(this.configuration).adSizeListByAdUnitId(adUnitId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {AdSizeBean} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdSizeControllerApi
     */
    public createAdSize(body: AdSizeBean, options?: any) {
        return AdSizeControllerApiFp(this.configuration).createAdSize(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdSizeControllerApi
     */
    public deleteAdSize(id: number, options?: any) {
        return AdSizeControllerApiFp(this.configuration).deleteAdSize(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {AdSizeBean} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdSizeControllerApi
     */
    public updateAdSize(body: AdSizeBean, id: number, options?: any) {
        return AdSizeControllerApiFp(this.configuration).updateAdSize(body, id, options)(this.fetch, this.basePath);
    }

}
/**
 * AdUnitControllerApi - fetch parameter creator
 * @export
 */
export const AdUnitControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adUnit(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling adUnit.');
            }
            const localVarPath = `/api/ad-unit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adUnitForList(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling adUnitForList.');
            }
            const localVarPath = `/api/ad-unit/list/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adUnitList(options: any = {}): FetchArgs {
            const localVarPath = `/api/ad-unit`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adUnitListAll(options: any = {}): FetchArgs {
            const localVarPath = `/api/ad-unit/all`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdUnitBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdUnit(body: AdUnitBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createAdUnit.');
            }
            const localVarPath = `/api/ad-unit`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AdUnitBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdUnit(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteAdUnit.');
            }
            const localVarPath = `/api/ad-unit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [siteId] 
         * @param {string} [name] 
         * @param {number} [bidFloor] 
         * @param {string} [adUnitType] 
         * @param {string} [auctionType] 
         * @param {boolean} [privateAuction] 
         * @param {boolean} [test] 
         * @param {boolean} [active] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredAdUnitList(siteId?: number, name?: string, bidFloor?: number, adUnitType?: string, auctionType?: string, privateAuction?: boolean, test?: boolean, active?: boolean, options: any = {}): FetchArgs {
            const localVarPath = `/api/ad-unit/all-filtered`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (siteId !== undefined) {
                localVarQueryParameter['siteId'] = siteId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (bidFloor !== undefined) {
                localVarQueryParameter['bidFloor'] = bidFloor;
            }

            if (adUnitType !== undefined) {
                localVarQueryParameter['adUnitType'] = adUnitType;
            }

            if (auctionType !== undefined) {
                localVarQueryParameter['auctionType'] = auctionType;
            }

            if (privateAuction !== undefined) {
                localVarQueryParameter['privateAuction'] = privateAuction;
            }

            if (test !== undefined) {
                localVarQueryParameter['test'] = test;
            }

            if (active !== undefined) {
                localVarQueryParameter['active'] = active;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {number} [publisherId] 
         * @param {number} [siteId] 
         * @param {boolean} [active] 
         * @param {boolean} [test] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredAdUnitPage(id?: number, publisherId?: number, siteId?: number, active?: boolean, test?: boolean, name?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/ad-unit/filtered`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (publisherId !== undefined) {
                localVarQueryParameter['publisherId'] = publisherId;
            }

            if (siteId !== undefined) {
                localVarQueryParameter['siteId'] = siteId;
            }

            if (active !== undefined) {
                localVarQueryParameter['active'] = active;
            }

            if (test !== undefined) {
                localVarQueryParameter['test'] = test;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdUnitBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdUnit(body: AdUnitBean, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateAdUnit.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateAdUnit.');
            }
            const localVarPath = `/api/ad-unit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AdUnitBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdUnitControllerApi - functional programming interface
 * @export
 */
export const AdUnitControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adUnit(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdUnitBean> {
            const localVarFetchArgs = AdUnitControllerApiFetchParamCreator(configuration).adUnit(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adUnitForList(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdUnitBeanInList> {
            const localVarFetchArgs = AdUnitControllerApiFetchParamCreator(configuration).adUnitForList(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adUnitList(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponseAdUnitBean> {
            const localVarFetchArgs = AdUnitControllerApiFetchParamCreator(configuration).adUnitList(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adUnitListAll(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SimpleAdUnitBean>> {
            const localVarFetchArgs = AdUnitControllerApiFetchParamCreator(configuration).adUnitListAll(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AdUnitBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdUnit(body: AdUnitBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdUnitBean> {
            const localVarFetchArgs = AdUnitControllerApiFetchParamCreator(configuration).createAdUnit(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdUnit(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdUnitControllerApiFetchParamCreator(configuration).deleteAdUnit(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [siteId] 
         * @param {string} [name] 
         * @param {number} [bidFloor] 
         * @param {string} [adUnitType] 
         * @param {string} [auctionType] 
         * @param {boolean} [privateAuction] 
         * @param {boolean} [test] 
         * @param {boolean} [active] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredAdUnitList(siteId?: number, name?: string, bidFloor?: number, adUnitType?: string, auctionType?: string, privateAuction?: boolean, test?: boolean, active?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<AdUnitBean>> {
            const localVarFetchArgs = AdUnitControllerApiFetchParamCreator(configuration).filteredAdUnitList(siteId, name, bidFloor, adUnitType, auctionType, privateAuction, test, active, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {number} [publisherId] 
         * @param {number} [siteId] 
         * @param {boolean} [active] 
         * @param {boolean} [test] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredAdUnitPage(id?: number, publisherId?: number, siteId?: number, active?: boolean, test?: boolean, name?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponseAdUnitBeanInList> {
            const localVarFetchArgs = AdUnitControllerApiFetchParamCreator(configuration).filteredAdUnitPage(id, publisherId, siteId, active, test, name, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AdUnitBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdUnit(body: AdUnitBean, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdUnitBean> {
            const localVarFetchArgs = AdUnitControllerApiFetchParamCreator(configuration).updateAdUnit(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AdUnitControllerApi - factory interface
 * @export
 */
export const AdUnitControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adUnit(id: number, options?: any) {
            return AdUnitControllerApiFp(configuration).adUnit(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adUnitForList(id: number, options?: any) {
            return AdUnitControllerApiFp(configuration).adUnitForList(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adUnitList(options?: any) {
            return AdUnitControllerApiFp(configuration).adUnitList(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adUnitListAll(options?: any) {
            return AdUnitControllerApiFp(configuration).adUnitListAll(options)(fetch, basePath);
        },
        /**
         * 
         * @param {AdUnitBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdUnit(body: AdUnitBean, options?: any) {
            return AdUnitControllerApiFp(configuration).createAdUnit(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdUnit(id: number, options?: any) {
            return AdUnitControllerApiFp(configuration).deleteAdUnit(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [siteId] 
         * @param {string} [name] 
         * @param {number} [bidFloor] 
         * @param {string} [adUnitType] 
         * @param {string} [auctionType] 
         * @param {boolean} [privateAuction] 
         * @param {boolean} [test] 
         * @param {boolean} [active] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredAdUnitList(siteId?: number, name?: string, bidFloor?: number, adUnitType?: string, auctionType?: string, privateAuction?: boolean, test?: boolean, active?: boolean, options?: any) {
            return AdUnitControllerApiFp(configuration).filteredAdUnitList(siteId, name, bidFloor, adUnitType, auctionType, privateAuction, test, active, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {number} [publisherId] 
         * @param {number} [siteId] 
         * @param {boolean} [active] 
         * @param {boolean} [test] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredAdUnitPage(id?: number, publisherId?: number, siteId?: number, active?: boolean, test?: boolean, name?: string, options?: any) {
            return AdUnitControllerApiFp(configuration).filteredAdUnitPage(id, publisherId, siteId, active, test, name, options)(fetch, basePath);
        },
        /**
         * 
         * @param {AdUnitBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdUnit(body: AdUnitBean, id: number, options?: any) {
            return AdUnitControllerApiFp(configuration).updateAdUnit(body, id, options)(fetch, basePath);
        },
    };
};

/**
 * AdUnitControllerApi - object-oriented interface
 * @export
 * @class AdUnitControllerApi
 * @extends {BaseAPI}
 */
export class AdUnitControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdUnitControllerApi
     */
    public adUnit(id: number, options?: any) {
        return AdUnitControllerApiFp(this.configuration).adUnit(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdUnitControllerApi
     */
    public adUnitForList(id: number, options?: any) {
        return AdUnitControllerApiFp(this.configuration).adUnitForList(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdUnitControllerApi
     */
    public adUnitList(options?: any) {
        return AdUnitControllerApiFp(this.configuration).adUnitList(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdUnitControllerApi
     */
    public adUnitListAll(options?: any) {
        return AdUnitControllerApiFp(this.configuration).adUnitListAll(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {AdUnitBean} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdUnitControllerApi
     */
    public createAdUnit(body: AdUnitBean, options?: any) {
        return AdUnitControllerApiFp(this.configuration).createAdUnit(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdUnitControllerApi
     */
    public deleteAdUnit(id: number, options?: any) {
        return AdUnitControllerApiFp(this.configuration).deleteAdUnit(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [siteId] 
     * @param {string} [name] 
     * @param {number} [bidFloor] 
     * @param {string} [adUnitType] 
     * @param {string} [auctionType] 
     * @param {boolean} [privateAuction] 
     * @param {boolean} [test] 
     * @param {boolean} [active] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdUnitControllerApi
     */
    public filteredAdUnitList(siteId?: number, name?: string, bidFloor?: number, adUnitType?: string, auctionType?: string, privateAuction?: boolean, test?: boolean, active?: boolean, options?: any) {
        return AdUnitControllerApiFp(this.configuration).filteredAdUnitList(siteId, name, bidFloor, adUnitType, auctionType, privateAuction, test, active, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [id] 
     * @param {number} [publisherId] 
     * @param {number} [siteId] 
     * @param {boolean} [active] 
     * @param {boolean} [test] 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdUnitControllerApi
     */
    public filteredAdUnitPage(id?: number, publisherId?: number, siteId?: number, active?: boolean, test?: boolean, name?: string, options?: any) {
        return AdUnitControllerApiFp(this.configuration).filteredAdUnitPage(id, publisherId, siteId, active, test, name, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {AdUnitBean} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdUnitControllerApi
     */
    public updateAdUnit(body: AdUnitBean, id: number, options?: any) {
        return AdUnitControllerApiFp(this.configuration).updateAdUnit(body, id, options)(this.fetch, this.basePath);
    }

}
/**
 * AdminFeaturesControllerApi - fetch parameter creator
 * @export
 */
export const AdminFeaturesControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {number} year 
         * @param {number} month 
         * @param {number} day 
         * @param {number} hour 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restore(id: string, year: number, month: number, day: number, hour: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling restore.');
            }
            // verify required parameter 'year' is not null or undefined
            if (year === null || year === undefined) {
                throw new RequiredError('year','Required parameter year was null or undefined when calling restore.');
            }
            // verify required parameter 'month' is not null or undefined
            if (month === null || month === undefined) {
                throw new RequiredError('month','Required parameter month was null or undefined when calling restore.');
            }
            // verify required parameter 'day' is not null or undefined
            if (day === null || day === undefined) {
                throw new RequiredError('day','Required parameter day was null or undefined when calling restore.');
            }
            // verify required parameter 'hour' is not null or undefined
            if (hour === null || hour === undefined) {
                throw new RequiredError('hour','Required parameter hour was null or undefined when calling restore.');
            }
            const localVarPath = `/api/admin/player-get-content`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (day !== undefined) {
                localVarQueryParameter['day'] = day;
            }

            if (hour !== undefined) {
                localVarQueryParameter['hour'] = hour;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreAdSizes(options: any = {}): FetchArgs {
            const localVarPath = `/api/admin/restore-ad-sizes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminFeaturesControllerApi - functional programming interface
 * @export
 */
export const AdminFeaturesControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {number} year 
         * @param {number} month 
         * @param {number} day 
         * @param {number} hour 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restore(id: string, year: number, month: number, day: number, hour: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = AdminFeaturesControllerApiFetchParamCreator(configuration).restore(id, year, month, day, hour, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreAdSizes(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = AdminFeaturesControllerApiFetchParamCreator(configuration).restoreAdSizes(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AdminFeaturesControllerApi - factory interface
 * @export
 */
export const AdminFeaturesControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {number} year 
         * @param {number} month 
         * @param {number} day 
         * @param {number} hour 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restore(id: string, year: number, month: number, day: number, hour: number, options?: any) {
            return AdminFeaturesControllerApiFp(configuration).restore(id, year, month, day, hour, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreAdSizes(options?: any) {
            return AdminFeaturesControllerApiFp(configuration).restoreAdSizes(options)(fetch, basePath);
        },
    };
};

/**
 * AdminFeaturesControllerApi - object-oriented interface
 * @export
 * @class AdminFeaturesControllerApi
 * @extends {BaseAPI}
 */
export class AdminFeaturesControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {number} year 
     * @param {number} month 
     * @param {number} day 
     * @param {number} hour 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFeaturesControllerApi
     */
    public restore(id: string, year: number, month: number, day: number, hour: number, options?: any) {
        return AdminFeaturesControllerApiFp(this.configuration).restore(id, year, month, day, hour, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFeaturesControllerApi
     */
    public restoreAdSizes(options?: any) {
        return AdminFeaturesControllerApiFp(this.configuration).restoreAdSizes(options)(this.fetch, this.basePath);
    }

}
/**
 * BrowserControllerApi - fetch parameter creator
 * @export
 */
export const BrowserControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        osList1(options: any = {}): FetchArgs {
            const localVarPath = `/api/browser`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BrowserControllerApi - functional programming interface
 * @export
 */
export const BrowserControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        osList1(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<BrowserBean>> {
            const localVarFetchArgs = BrowserControllerApiFetchParamCreator(configuration).osList1(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * BrowserControllerApi - factory interface
 * @export
 */
export const BrowserControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        osList1(options?: any) {
            return BrowserControllerApiFp(configuration).osList1(options)(fetch, basePath);
        },
    };
};

/**
 * BrowserControllerApi - object-oriented interface
 * @export
 * @class BrowserControllerApi
 * @extends {BaseAPI}
 */
export class BrowserControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrowserControllerApi
     */
    public osList1(options?: any) {
        return BrowserControllerApiFp(this.configuration).osList1(options)(this.fetch, this.basePath);
    }

}
/**
 * CountryControllerApi - fetch parameter creator
 * @export
 */
export const CountryControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all countries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countries(options: any = {}): FetchArgs {
            const localVarPath = `/api/country/all`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CountryControllerApi - functional programming interface
 * @export
 */
export const CountryControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Get all countries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countries(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CountryBean>> {
            const localVarFetchArgs = CountryControllerApiFetchParamCreator(configuration).countries(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CountryControllerApi - factory interface
 * @export
 */
export const CountryControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Get all countries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countries(options?: any) {
            return CountryControllerApiFp(configuration).countries(options)(fetch, basePath);
        },
    };
};

/**
 * CountryControllerApi - object-oriented interface
 * @export
 * @class CountryControllerApi
 * @extends {BaseAPI}
 */
export class CountryControllerApi extends BaseAPI {
    /**
     * Get all countries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryControllerApi
     */
    public countries(options?: any) {
        return CountryControllerApiFp(this.configuration).countries(options)(this.fetch, this.basePath);
    }

}
/**
 * CountrySubdivisionControllerApi - fetch parameter creator
 * @export
 */
export const CountrySubdivisionControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all subdivisions by country ids
         * @param {Array<number>} [countryIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countrySubdivisions(countryIds?: Array<number>, options: any = {}): FetchArgs {
            const localVarPath = `/api/country-subdivision/filtered`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (countryIds) {
                localVarQueryParameter['country_ids'] = countryIds;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all countries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subdivisions(options: any = {}): FetchArgs {
            const localVarPath = `/api/country-subdivision/all`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CountrySubdivisionControllerApi - functional programming interface
 * @export
 */
export const CountrySubdivisionControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Get all subdivisions by country ids
         * @param {Array<number>} [countryIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countrySubdivisions(countryIds?: Array<number>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CountrySubdivisionBean>> {
            const localVarFetchArgs = CountrySubdivisionControllerApiFetchParamCreator(configuration).countrySubdivisions(countryIds, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Get all countries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subdivisions(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CountrySubdivisionBean>> {
            const localVarFetchArgs = CountrySubdivisionControllerApiFetchParamCreator(configuration).subdivisions(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CountrySubdivisionControllerApi - factory interface
 * @export
 */
export const CountrySubdivisionControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Get all subdivisions by country ids
         * @param {Array<number>} [countryIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countrySubdivisions(countryIds?: Array<number>, options?: any) {
            return CountrySubdivisionControllerApiFp(configuration).countrySubdivisions(countryIds, options)(fetch, basePath);
        },
        /**
         * Get all countries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subdivisions(options?: any) {
            return CountrySubdivisionControllerApiFp(configuration).subdivisions(options)(fetch, basePath);
        },
    };
};

/**
 * CountrySubdivisionControllerApi - object-oriented interface
 * @export
 * @class CountrySubdivisionControllerApi
 * @extends {BaseAPI}
 */
export class CountrySubdivisionControllerApi extends BaseAPI {
    /**
     * Get all subdivisions by country ids
     * @param {Array<number>} [countryIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountrySubdivisionControllerApi
     */
    public countrySubdivisions(countryIds?: Array<number>, options?: any) {
        return CountrySubdivisionControllerApiFp(this.configuration).countrySubdivisions(countryIds, options)(this.fetch, this.basePath);
    }

    /**
     * Get all countries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountrySubdivisionControllerApi
     */
    public subdivisions(options?: any) {
        return CountrySubdivisionControllerApiFp(this.configuration).subdivisions(options)(this.fetch, this.basePath);
    }

}
/**
 * CurrencyControllerApi - fetch parameter creator
 * @export
 */
export const CurrencyControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} period 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieve1(period: string, options: any = {}): FetchArgs {
            // verify required parameter 'period' is not null or undefined
            if (period === null || period === undefined) {
                throw new RequiredError('period','Required parameter period was null or undefined when calling retrieve1.');
            }
            const localVarPath = `/api/currency`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CurrencyControllerApi - functional programming interface
 * @export
 */
export const CurrencyControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} period 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieve1(period: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CurrencyBean> {
            const localVarFetchArgs = CurrencyControllerApiFetchParamCreator(configuration).retrieve1(period, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CurrencyControllerApi - factory interface
 * @export
 */
export const CurrencyControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} period 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieve1(period: string, options?: any) {
            return CurrencyControllerApiFp(configuration).retrieve1(period, options)(fetch, basePath);
        },
    };
};

/**
 * CurrencyControllerApi - object-oriented interface
 * @export
 * @class CurrencyControllerApi
 * @extends {BaseAPI}
 */
export class CurrencyControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} period 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrencyControllerApi
     */
    public retrieve1(period: string, options?: any) {
        return CurrencyControllerApiFp(this.configuration).retrieve1(period, options)(this.fetch, this.basePath);
    }

}
/**
 * DashboardControllerApi - fetch parameter creator
 * @export
 */
export const DashboardControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DashboardChartRequestBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardChart(body: DashboardChartRequestBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getDashboardChart.');
            }
            const localVarPath = `/api/dashboard/chart`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DashboardChartRequestBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DashboardStatRequestBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardStat(body: DashboardStatRequestBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getDashboardStat.');
            }
            const localVarPath = `/api/dashboard/stat`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DashboardStatRequestBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DashboardTopRequestBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardTop(body: DashboardTopRequestBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getDashboardTop.');
            }
            const localVarPath = `/api/dashboard/top`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DashboardTopRequestBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardControllerApi - functional programming interface
 * @export
 */
export const DashboardControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DashboardChartRequestBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardChart(body: DashboardChartRequestBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DashboardChartBean> {
            const localVarFetchArgs = DashboardControllerApiFetchParamCreator(configuration).getDashboardChart(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {DashboardStatRequestBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardStat(body: DashboardStatRequestBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DashboardStatBean> {
            const localVarFetchArgs = DashboardControllerApiFetchParamCreator(configuration).getDashboardStat(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {DashboardTopRequestBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardTop(body: DashboardTopRequestBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DashboardTopBean> {
            const localVarFetchArgs = DashboardControllerApiFetchParamCreator(configuration).getDashboardTop(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DashboardControllerApi - factory interface
 * @export
 */
export const DashboardControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {DashboardChartRequestBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardChart(body: DashboardChartRequestBean, options?: any) {
            return DashboardControllerApiFp(configuration).getDashboardChart(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {DashboardStatRequestBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardStat(body: DashboardStatRequestBean, options?: any) {
            return DashboardControllerApiFp(configuration).getDashboardStat(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {DashboardTopRequestBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardTop(body: DashboardTopRequestBean, options?: any) {
            return DashboardControllerApiFp(configuration).getDashboardTop(body, options)(fetch, basePath);
        },
    };
};

/**
 * DashboardControllerApi - object-oriented interface
 * @export
 * @class DashboardControllerApi
 * @extends {BaseAPI}
 */
export class DashboardControllerApi extends BaseAPI {
    /**
     * 
     * @param {DashboardChartRequestBean} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardControllerApi
     */
    public getDashboardChart(body: DashboardChartRequestBean, options?: any) {
        return DashboardControllerApiFp(this.configuration).getDashboardChart(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {DashboardStatRequestBean} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardControllerApi
     */
    public getDashboardStat(body: DashboardStatRequestBean, options?: any) {
        return DashboardControllerApiFp(this.configuration).getDashboardStat(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {DashboardTopRequestBean} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardControllerApi
     */
    public getDashboardTop(body: DashboardTopRequestBean, options?: any) {
        return DashboardControllerApiFp(this.configuration).getDashboardTop(body, options)(this.fetch, this.basePath);
    }

}
/**
 * DealControllerApi - fetch parameter creator
 * @export
 */
export const DealControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DealBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeal(body: DealBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createDeal.');
            }
            const localVarPath = `/api/deal`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DealBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealListAll(options: any = {}): FetchArgs {
            const localVarPath = `/api/deal/all`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeal(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteDeal.');
            }
            const localVarPath = `/api/deal/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {string} [publicId] 
         * @param {string} [name] 
         * @param {number} [publisherId] 
         * @param {number} [bidFloor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredDealList(id?: number, publicId?: string, name?: string, publisherId?: number, bidFloor?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/deal/filtered`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (publicId !== undefined) {
                localVarQueryParameter['publicId'] = publicId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (publisherId !== undefined) {
                localVarQueryParameter['publisherId'] = publisherId;
            }

            if (bidFloor !== undefined) {
                localVarQueryParameter['bidFloor'] = bidFloor;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveDeal(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling retrieveDeal.');
            }
            const localVarPath = `/api/deal/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveDealForList(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling retrieveDealForList.');
            }
            const localVarPath = `/api/deal/list/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DealBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeal(body: DealBean, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateDeal.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateDeal.');
            }
            const localVarPath = `/api/deal/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DealBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DealControllerApi - functional programming interface
 * @export
 */
export const DealControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DealBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeal(body: DealBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DealBean> {
            const localVarFetchArgs = DealControllerApiFetchParamCreator(configuration).createDeal(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealListAll(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<NamedBean>> {
            const localVarFetchArgs = DealControllerApiFetchParamCreator(configuration).dealListAll(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeal(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DealControllerApiFetchParamCreator(configuration).deleteDeal(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {string} [publicId] 
         * @param {string} [name] 
         * @param {number} [publisherId] 
         * @param {number} [bidFloor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredDealList(id?: number, publicId?: string, name?: string, publisherId?: number, bidFloor?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponseDealBeanInList> {
            const localVarFetchArgs = DealControllerApiFetchParamCreator(configuration).filteredDealList(id, publicId, name, publisherId, bidFloor, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveDeal(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DealBean> {
            const localVarFetchArgs = DealControllerApiFetchParamCreator(configuration).retrieveDeal(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveDealForList(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DealBeanInList> {
            const localVarFetchArgs = DealControllerApiFetchParamCreator(configuration).retrieveDealForList(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {DealBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeal(body: DealBean, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DealBean> {
            const localVarFetchArgs = DealControllerApiFetchParamCreator(configuration).updateDeal(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DealControllerApi - factory interface
 * @export
 */
export const DealControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {DealBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeal(body: DealBean, options?: any) {
            return DealControllerApiFp(configuration).createDeal(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealListAll(options?: any) {
            return DealControllerApiFp(configuration).dealListAll(options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeal(id: number, options?: any) {
            return DealControllerApiFp(configuration).deleteDeal(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {string} [publicId] 
         * @param {string} [name] 
         * @param {number} [publisherId] 
         * @param {number} [bidFloor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredDealList(id?: number, publicId?: string, name?: string, publisherId?: number, bidFloor?: number, options?: any) {
            return DealControllerApiFp(configuration).filteredDealList(id, publicId, name, publisherId, bidFloor, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveDeal(id: number, options?: any) {
            return DealControllerApiFp(configuration).retrieveDeal(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveDealForList(id: number, options?: any) {
            return DealControllerApiFp(configuration).retrieveDealForList(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {DealBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeal(body: DealBean, id: number, options?: any) {
            return DealControllerApiFp(configuration).updateDeal(body, id, options)(fetch, basePath);
        },
    };
};

/**
 * DealControllerApi - object-oriented interface
 * @export
 * @class DealControllerApi
 * @extends {BaseAPI}
 */
export class DealControllerApi extends BaseAPI {
    /**
     * 
     * @param {DealBean} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealControllerApi
     */
    public createDeal(body: DealBean, options?: any) {
        return DealControllerApiFp(this.configuration).createDeal(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealControllerApi
     */
    public dealListAll(options?: any) {
        return DealControllerApiFp(this.configuration).dealListAll(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealControllerApi
     */
    public deleteDeal(id: number, options?: any) {
        return DealControllerApiFp(this.configuration).deleteDeal(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [id] 
     * @param {string} [publicId] 
     * @param {string} [name] 
     * @param {number} [publisherId] 
     * @param {number} [bidFloor] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealControllerApi
     */
    public filteredDealList(id?: number, publicId?: string, name?: string, publisherId?: number, bidFloor?: number, options?: any) {
        return DealControllerApiFp(this.configuration).filteredDealList(id, publicId, name, publisherId, bidFloor, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealControllerApi
     */
    public retrieveDeal(id: number, options?: any) {
        return DealControllerApiFp(this.configuration).retrieveDeal(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealControllerApi
     */
    public retrieveDealForList(id: number, options?: any) {
        return DealControllerApiFp(this.configuration).retrieveDealForList(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {DealBean} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealControllerApi
     */
    public updateDeal(body: DealBean, id: number, options?: any) {
        return DealControllerApiFp(this.configuration).updateDeal(body, id, options)(this.fetch, this.basePath);
    }

}
/**
 * DirectAdControllerApi - fetch parameter creator
 * @export
 */
export const DirectAdControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allDirectAdsAsSimple(options: any = {}): FetchArgs {
            const localVarPath = `/api/direct-ad/all-simple`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DirectAdBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDirectAd(body: DirectAdBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createDirectAd.');
            }
            const localVarPath = `/api/direct-ad`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DirectAdBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDirectAd(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteDirectAd.');
            }
            const localVarPath = `/api/direct-ad/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directAd(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling directAd.');
            }
            const localVarPath = `/api/direct-ad/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {string} [name] 
         * @param {string} [adSizeName] 
         * @param {boolean} [active] 
         * @param {string} [rtbDetailsType] 
         * @param {string} [directAdCreativeType] 
         * @param {Array<number>} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directAdFilteredList(id?: number, name?: string, adSizeName?: string, active?: boolean, rtbDetailsType?: string, directAdCreativeType?: string, campaignId?: Array<number>, options: any = {}): FetchArgs {
            const localVarPath = `/api/direct-ad/filtered`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (adSizeName !== undefined) {
                localVarQueryParameter['adSizeName'] = adSizeName;
            }

            if (active !== undefined) {
                localVarQueryParameter['active'] = active;
            }

            if (rtbDetailsType !== undefined) {
                localVarQueryParameter['rtbDetailsType'] = rtbDetailsType;
            }

            if (directAdCreativeType !== undefined) {
                localVarQueryParameter['directAdCreativeType'] = directAdCreativeType;
            }

            if (campaignId) {
                localVarQueryParameter['campaignId'] = campaignId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directAdForList(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling directAdForList.');
            }
            const localVarPath = `/api/direct-ad/list/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directAdList(options: any = {}): FetchArgs {
            const localVarPath = `/api/direct-ad`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DirectAdBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDirectAd(body: DirectAdBean, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateDirectAd.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateDirectAd.');
            }
            const localVarPath = `/api/direct-ad/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DirectAdBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DirectAdControllerApi - functional programming interface
 * @export
 */
export const DirectAdControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allDirectAdsAsSimple(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<NamedBean>> {
            const localVarFetchArgs = DirectAdControllerApiFetchParamCreator(configuration).allDirectAdsAsSimple(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {DirectAdBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDirectAd(body: DirectAdBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DirectAdBean> {
            const localVarFetchArgs = DirectAdControllerApiFetchParamCreator(configuration).createDirectAd(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDirectAd(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DirectAdControllerApiFetchParamCreator(configuration).deleteDirectAd(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directAd(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DirectAdBean> {
            const localVarFetchArgs = DirectAdControllerApiFetchParamCreator(configuration).directAd(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {string} [name] 
         * @param {string} [adSizeName] 
         * @param {boolean} [active] 
         * @param {string} [rtbDetailsType] 
         * @param {string} [directAdCreativeType] 
         * @param {Array<number>} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directAdFilteredList(id?: number, name?: string, adSizeName?: string, active?: boolean, rtbDetailsType?: string, directAdCreativeType?: string, campaignId?: Array<number>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponseDirectAdBeanInList> {
            const localVarFetchArgs = DirectAdControllerApiFetchParamCreator(configuration).directAdFilteredList(id, name, adSizeName, active, rtbDetailsType, directAdCreativeType, campaignId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directAdForList(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DirectAdBeanInList> {
            const localVarFetchArgs = DirectAdControllerApiFetchParamCreator(configuration).directAdForList(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directAdList(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponseDirectAdBeanInList> {
            const localVarFetchArgs = DirectAdControllerApiFetchParamCreator(configuration).directAdList(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {DirectAdBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDirectAd(body: DirectAdBean, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DirectAdBean> {
            const localVarFetchArgs = DirectAdControllerApiFetchParamCreator(configuration).updateDirectAd(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DirectAdControllerApi - factory interface
 * @export
 */
export const DirectAdControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allDirectAdsAsSimple(options?: any) {
            return DirectAdControllerApiFp(configuration).allDirectAdsAsSimple(options)(fetch, basePath);
        },
        /**
         * 
         * @param {DirectAdBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDirectAd(body: DirectAdBean, options?: any) {
            return DirectAdControllerApiFp(configuration).createDirectAd(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDirectAd(id: number, options?: any) {
            return DirectAdControllerApiFp(configuration).deleteDirectAd(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directAd(id: number, options?: any) {
            return DirectAdControllerApiFp(configuration).directAd(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {string} [name] 
         * @param {string} [adSizeName] 
         * @param {boolean} [active] 
         * @param {string} [rtbDetailsType] 
         * @param {string} [directAdCreativeType] 
         * @param {Array<number>} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directAdFilteredList(id?: number, name?: string, adSizeName?: string, active?: boolean, rtbDetailsType?: string, directAdCreativeType?: string, campaignId?: Array<number>, options?: any) {
            return DirectAdControllerApiFp(configuration).directAdFilteredList(id, name, adSizeName, active, rtbDetailsType, directAdCreativeType, campaignId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directAdForList(id: number, options?: any) {
            return DirectAdControllerApiFp(configuration).directAdForList(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directAdList(options?: any) {
            return DirectAdControllerApiFp(configuration).directAdList(options)(fetch, basePath);
        },
        /**
         * 
         * @param {DirectAdBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDirectAd(body: DirectAdBean, id: number, options?: any) {
            return DirectAdControllerApiFp(configuration).updateDirectAd(body, id, options)(fetch, basePath);
        },
    };
};

/**
 * DirectAdControllerApi - object-oriented interface
 * @export
 * @class DirectAdControllerApi
 * @extends {BaseAPI}
 */
export class DirectAdControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectAdControllerApi
     */
    public allDirectAdsAsSimple(options?: any) {
        return DirectAdControllerApiFp(this.configuration).allDirectAdsAsSimple(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {DirectAdBean} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectAdControllerApi
     */
    public createDirectAd(body: DirectAdBean, options?: any) {
        return DirectAdControllerApiFp(this.configuration).createDirectAd(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectAdControllerApi
     */
    public deleteDirectAd(id: number, options?: any) {
        return DirectAdControllerApiFp(this.configuration).deleteDirectAd(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectAdControllerApi
     */
    public directAd(id: number, options?: any) {
        return DirectAdControllerApiFp(this.configuration).directAd(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [id] 
     * @param {string} [name] 
     * @param {string} [adSizeName] 
     * @param {boolean} [active] 
     * @param {string} [rtbDetailsType] 
     * @param {string} [directAdCreativeType] 
     * @param {Array<number>} [campaignId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectAdControllerApi
     */
    public directAdFilteredList(id?: number, name?: string, adSizeName?: string, active?: boolean, rtbDetailsType?: string, directAdCreativeType?: string, campaignId?: Array<number>, options?: any) {
        return DirectAdControllerApiFp(this.configuration).directAdFilteredList(id, name, adSizeName, active, rtbDetailsType, directAdCreativeType, campaignId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectAdControllerApi
     */
    public directAdForList(id: number, options?: any) {
        return DirectAdControllerApiFp(this.configuration).directAdForList(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectAdControllerApi
     */
    public directAdList(options?: any) {
        return DirectAdControllerApiFp(this.configuration).directAdList(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {DirectAdBean} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectAdControllerApi
     */
    public updateDirectAd(body: DirectAdBean, id: number, options?: any) {
        return DirectAdControllerApiFp(this.configuration).updateDirectAd(body, id, options)(this.fetch, this.basePath);
    }

}
/**
 * DirectCampaignControllerApi - fetch parameter creator
 * @export
 */
export const DirectCampaignControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DirectCampaignBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDirectCampaign(body: DirectCampaignBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createDirectCampaign.');
            }
            const localVarPath = `/api/direct-campaign`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DirectCampaignBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDirectCampaign(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteDirectCampaign.');
            }
            const localVarPath = `/api/direct-campaign/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {boolean} [active] 
         * @param {string} [name] 
         * @param {string} [cappingType] 
         * @param {string} [passingType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directCampaignListByFilters(id?: number, active?: boolean, name?: string, cappingType?: string, passingType?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/direct-campaign/filtered`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (active !== undefined) {
                localVarQueryParameter['active'] = active;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (cappingType !== undefined) {
                localVarQueryParameter['cappingType'] = cappingType;
            }

            if (passingType !== undefined) {
                localVarQueryParameter['passingType'] = passingType;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDirectCampaign(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getDirectCampaign.');
            }
            const localVarPath = `/api/direct-campaign/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDirectCampaignForList(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getDirectCampaignForList.');
            }
            const localVarPath = `/api/direct-campaign/list/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDirectCampaignList(options: any = {}): FetchArgs {
            const localVarPath = `/api/direct-campaign/all`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDirectCampaignSortedByNameList(options: any = {}): FetchArgs {
            const localVarPath = `/api/direct-campaign`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DirectCampaignBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDirectCampaign(body: DirectCampaignBean, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateDirectCampaign.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateDirectCampaign.');
            }
            const localVarPath = `/api/direct-campaign/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DirectCampaignBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DirectCampaignControllerApi - functional programming interface
 * @export
 */
export const DirectCampaignControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DirectCampaignBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDirectCampaign(body: DirectCampaignBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DirectCampaignBean> {
            const localVarFetchArgs = DirectCampaignControllerApiFetchParamCreator(configuration).createDirectCampaign(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDirectCampaign(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DirectCampaignControllerApiFetchParamCreator(configuration).deleteDirectCampaign(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {boolean} [active] 
         * @param {string} [name] 
         * @param {string} [cappingType] 
         * @param {string} [passingType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directCampaignListByFilters(id?: number, active?: boolean, name?: string, cappingType?: string, passingType?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponseDirectCampaignBeanInList> {
            const localVarFetchArgs = DirectCampaignControllerApiFetchParamCreator(configuration).directCampaignListByFilters(id, active, name, cappingType, passingType, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDirectCampaign(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DirectCampaignBean> {
            const localVarFetchArgs = DirectCampaignControllerApiFetchParamCreator(configuration).getDirectCampaign(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDirectCampaignForList(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DirectCampaignBeanInList> {
            const localVarFetchArgs = DirectCampaignControllerApiFetchParamCreator(configuration).getDirectCampaignForList(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDirectCampaignList(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<NamedBean>> {
            const localVarFetchArgs = DirectCampaignControllerApiFetchParamCreator(configuration).getDirectCampaignList(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDirectCampaignSortedByNameList(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponseDirectCampaignBeanInList> {
            const localVarFetchArgs = DirectCampaignControllerApiFetchParamCreator(configuration).getDirectCampaignSortedByNameList(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {DirectCampaignBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDirectCampaign(body: DirectCampaignBean, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DirectCampaignBean> {
            const localVarFetchArgs = DirectCampaignControllerApiFetchParamCreator(configuration).updateDirectCampaign(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DirectCampaignControllerApi - factory interface
 * @export
 */
export const DirectCampaignControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {DirectCampaignBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDirectCampaign(body: DirectCampaignBean, options?: any) {
            return DirectCampaignControllerApiFp(configuration).createDirectCampaign(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDirectCampaign(id: number, options?: any) {
            return DirectCampaignControllerApiFp(configuration).deleteDirectCampaign(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {boolean} [active] 
         * @param {string} [name] 
         * @param {string} [cappingType] 
         * @param {string} [passingType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directCampaignListByFilters(id?: number, active?: boolean, name?: string, cappingType?: string, passingType?: string, options?: any) {
            return DirectCampaignControllerApiFp(configuration).directCampaignListByFilters(id, active, name, cappingType, passingType, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDirectCampaign(id: number, options?: any) {
            return DirectCampaignControllerApiFp(configuration).getDirectCampaign(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDirectCampaignForList(id: number, options?: any) {
            return DirectCampaignControllerApiFp(configuration).getDirectCampaignForList(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDirectCampaignList(options?: any) {
            return DirectCampaignControllerApiFp(configuration).getDirectCampaignList(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDirectCampaignSortedByNameList(options?: any) {
            return DirectCampaignControllerApiFp(configuration).getDirectCampaignSortedByNameList(options)(fetch, basePath);
        },
        /**
         * 
         * @param {DirectCampaignBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDirectCampaign(body: DirectCampaignBean, id: number, options?: any) {
            return DirectCampaignControllerApiFp(configuration).updateDirectCampaign(body, id, options)(fetch, basePath);
        },
    };
};

/**
 * DirectCampaignControllerApi - object-oriented interface
 * @export
 * @class DirectCampaignControllerApi
 * @extends {BaseAPI}
 */
export class DirectCampaignControllerApi extends BaseAPI {
    /**
     * 
     * @param {DirectCampaignBean} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectCampaignControllerApi
     */
    public createDirectCampaign(body: DirectCampaignBean, options?: any) {
        return DirectCampaignControllerApiFp(this.configuration).createDirectCampaign(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectCampaignControllerApi
     */
    public deleteDirectCampaign(id: number, options?: any) {
        return DirectCampaignControllerApiFp(this.configuration).deleteDirectCampaign(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [id] 
     * @param {boolean} [active] 
     * @param {string} [name] 
     * @param {string} [cappingType] 
     * @param {string} [passingType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectCampaignControllerApi
     */
    public directCampaignListByFilters(id?: number, active?: boolean, name?: string, cappingType?: string, passingType?: string, options?: any) {
        return DirectCampaignControllerApiFp(this.configuration).directCampaignListByFilters(id, active, name, cappingType, passingType, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectCampaignControllerApi
     */
    public getDirectCampaign(id: number, options?: any) {
        return DirectCampaignControllerApiFp(this.configuration).getDirectCampaign(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectCampaignControllerApi
     */
    public getDirectCampaignForList(id: number, options?: any) {
        return DirectCampaignControllerApiFp(this.configuration).getDirectCampaignForList(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectCampaignControllerApi
     */
    public getDirectCampaignList(options?: any) {
        return DirectCampaignControllerApiFp(this.configuration).getDirectCampaignList(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectCampaignControllerApi
     */
    public getDirectCampaignSortedByNameList(options?: any) {
        return DirectCampaignControllerApiFp(this.configuration).getDirectCampaignSortedByNameList(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {DirectCampaignBean} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectCampaignControllerApi
     */
    public updateDirectCampaign(body: DirectCampaignBean, id: number, options?: any) {
        return DirectCampaignControllerApiFp(this.configuration).updateDirectCampaign(body, id, options)(this.fetch, this.basePath);
    }

}
/**
 * DirectVideoControllerApi - fetch parameter creator
 * @export
 */
export const DirectVideoControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Blob} [video] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDirectVideo(video?: Blob, options: any = {}): FetchArgs {
            const localVarPath = `/api/direct-video`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            if (video !== undefined) {
                localVarFormParams.set('video', video as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDirectVideo(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteDirectVideo.');
            }
            const localVarPath = `/api/direct-video`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DirectVideoControllerApi - functional programming interface
 * @export
 */
export const DirectVideoControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Blob} [video] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDirectVideo(video?: Blob, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DirectVideoBean> {
            const localVarFetchArgs = DirectVideoControllerApiFetchParamCreator(configuration).createDirectVideo(video, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDirectVideo(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DirectVideoControllerApiFetchParamCreator(configuration).deleteDirectVideo(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DirectVideoControllerApi - factory interface
 * @export
 */
export const DirectVideoControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {Blob} [video] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDirectVideo(video?: Blob, options?: any) {
            return DirectVideoControllerApiFp(configuration).createDirectVideo(video, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDirectVideo(id: number, options?: any) {
            return DirectVideoControllerApiFp(configuration).deleteDirectVideo(id, options)(fetch, basePath);
        },
    };
};

/**
 * DirectVideoControllerApi - object-oriented interface
 * @export
 * @class DirectVideoControllerApi
 * @extends {BaseAPI}
 */
export class DirectVideoControllerApi extends BaseAPI {
    /**
     * 
     * @param {Blob} [video] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectVideoControllerApi
     */
    public createDirectVideo(video?: Blob, options?: any) {
        return DirectVideoControllerApiFp(this.configuration).createDirectVideo(video, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectVideoControllerApi
     */
    public deleteDirectVideo(id: number, options?: any) {
        return DirectVideoControllerApiFp(this.configuration).deleteDirectVideo(id, options)(this.fetch, this.basePath);
    }

}
/**
 * DspControllerApi - fetch parameter creator
 * @export
 */
export const DspControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DspBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDsp(body: DspBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createDsp.');
            }
            const localVarPath = `/api/dsp`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DspBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDsp(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteDsp.');
            }
            const localVarPath = `/api/dsp/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dsp(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling dsp.');
            }
            const localVarPath = `/api/dsp/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dspForList(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling dspForList.');
            }
            const localVarPath = `/api/dsp/list/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dspList(options: any = {}): FetchArgs {
            const localVarPath = `/api/dsp`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dspListAll(options: any = {}): FetchArgs {
            const localVarPath = `/api/dsp/all`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DspBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDsp(body: DspBean, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateDsp.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateDsp.');
            }
            const localVarPath = `/api/dsp/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DspBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DspControllerApi - functional programming interface
 * @export
 */
export const DspControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DspBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDsp(body: DspBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DspBean> {
            const localVarFetchArgs = DspControllerApiFetchParamCreator(configuration).createDsp(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDsp(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DspControllerApiFetchParamCreator(configuration).deleteDsp(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dsp(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DspBean> {
            const localVarFetchArgs = DspControllerApiFetchParamCreator(configuration).dsp(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dspForList(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DspBeanInList> {
            const localVarFetchArgs = DspControllerApiFetchParamCreator(configuration).dspForList(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dspList(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponseDspBeanInList> {
            const localVarFetchArgs = DspControllerApiFetchParamCreator(configuration).dspList(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dspListAll(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<NamedBean>> {
            const localVarFetchArgs = DspControllerApiFetchParamCreator(configuration).dspListAll(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {DspBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDsp(body: DspBean, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DspBean> {
            const localVarFetchArgs = DspControllerApiFetchParamCreator(configuration).updateDsp(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DspControllerApi - factory interface
 * @export
 */
export const DspControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {DspBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDsp(body: DspBean, options?: any) {
            return DspControllerApiFp(configuration).createDsp(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDsp(id: number, options?: any) {
            return DspControllerApiFp(configuration).deleteDsp(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dsp(id: number, options?: any) {
            return DspControllerApiFp(configuration).dsp(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dspForList(id: number, options?: any) {
            return DspControllerApiFp(configuration).dspForList(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dspList(options?: any) {
            return DspControllerApiFp(configuration).dspList(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dspListAll(options?: any) {
            return DspControllerApiFp(configuration).dspListAll(options)(fetch, basePath);
        },
        /**
         * 
         * @param {DspBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDsp(body: DspBean, id: number, options?: any) {
            return DspControllerApiFp(configuration).updateDsp(body, id, options)(fetch, basePath);
        },
    };
};

/**
 * DspControllerApi - object-oriented interface
 * @export
 * @class DspControllerApi
 * @extends {BaseAPI}
 */
export class DspControllerApi extends BaseAPI {
    /**
     * 
     * @param {DspBean} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DspControllerApi
     */
    public createDsp(body: DspBean, options?: any) {
        return DspControllerApiFp(this.configuration).createDsp(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DspControllerApi
     */
    public deleteDsp(id: number, options?: any) {
        return DspControllerApiFp(this.configuration).deleteDsp(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DspControllerApi
     */
    public dsp(id: number, options?: any) {
        return DspControllerApiFp(this.configuration).dsp(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DspControllerApi
     */
    public dspForList(id: number, options?: any) {
        return DspControllerApiFp(this.configuration).dspForList(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DspControllerApi
     */
    public dspList(options?: any) {
        return DspControllerApiFp(this.configuration).dspList(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DspControllerApi
     */
    public dspListAll(options?: any) {
        return DspControllerApiFp(this.configuration).dspListAll(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {DspBean} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DspControllerApi
     */
    public updateDsp(body: DspBean, id: number, options?: any) {
        return DspControllerApiFp(this.configuration).updateDsp(body, id, options)(this.fetch, this.basePath);
    }

}
/**
 * DspReportControllerApi - fetch parameter creator
 * @export
 */
export const DspReportControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} apikey 
         * @param {string} start 
         * @param {string} end 
         * @param {string} [timezone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDspStatistic(apikey: string, start: string, end: string, timezone?: string, options: any = {}): FetchArgs {
            // verify required parameter 'apikey' is not null or undefined
            if (apikey === null || apikey === undefined) {
                throw new RequiredError('apikey','Required parameter apikey was null or undefined when calling getDspStatistic.');
            }
            // verify required parameter 'start' is not null or undefined
            if (start === null || start === undefined) {
                throw new RequiredError('start','Required parameter start was null or undefined when calling getDspStatistic.');
            }
            // verify required parameter 'end' is not null or undefined
            if (end === null || end === undefined) {
                throw new RequiredError('end','Required parameter end was null or undefined when calling getDspStatistic.');
            }
            const localVarPath = `/dspReport`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apikey !== undefined) {
                localVarQueryParameter['apikey'] = apikey;
            }

            if (timezone !== undefined) {
                localVarQueryParameter['timezone'] = timezone;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any).toISOString();
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DspReportControllerApi - functional programming interface
 * @export
 */
export const DspReportControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} apikey 
         * @param {string} start 
         * @param {string} end 
         * @param {string} [timezone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDspStatistic(apikey: string, start: string, end: string, timezone?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DspReportStats> {
            const localVarFetchArgs = DspReportControllerApiFetchParamCreator(configuration).getDspStatistic(apikey, start, end, timezone, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DspReportControllerApi - factory interface
 * @export
 */
export const DspReportControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} apikey 
         * @param {string} start 
         * @param {string} end 
         * @param {string} [timezone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDspStatistic(apikey: string, start: string, end: string, timezone?: string, options?: any) {
            return DspReportControllerApiFp(configuration).getDspStatistic(apikey, start, end, timezone, options)(fetch, basePath);
        },
    };
};

/**
 * DspReportControllerApi - object-oriented interface
 * @export
 * @class DspReportControllerApi
 * @extends {BaseAPI}
 */
export class DspReportControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} apikey 
     * @param {string} start 
     * @param {string} end 
     * @param {string} [timezone] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DspReportControllerApi
     */
    public getDspStatistic(apikey: string, start: string, end: string, timezone?: string, options?: any) {
        return DspReportControllerApiFp(this.configuration).getDspStatistic(apikey, start, end, timezone, options)(this.fetch, this.basePath);
    }

}
/**
 * EmailControllerApi - fetch parameter creator
 * @export
 */
export const EmailControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScheduledReport(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteScheduledReport.');
            }
            const localVarPath = `/api/mail/report/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduledReportQuery(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getScheduledReportQuery.');
            }
            const localVarPath = `/api/mail/reportQuery/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EmailDetailsDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMail(body: EmailDetailsDTO, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling sendMail.');
            }
            const localVarPath = `/api/mail/send`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"EmailDetailsDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ScheduledReportBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMailWithReport(body: ScheduledReportBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling sendMailWithReport.');
            }
            const localVarPath = `/api/mail/report`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ScheduledReportBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmailControllerApi - functional programming interface
 * @export
 */
export const EmailControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScheduledReport(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = EmailControllerApiFetchParamCreator(configuration).deleteScheduledReport(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduledReportQuery(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ScheduledReportBean> {
            const localVarFetchArgs = EmailControllerApiFetchParamCreator(configuration).getScheduledReportQuery(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {EmailDetailsDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMail(body: EmailDetailsDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = EmailControllerApiFetchParamCreator(configuration).sendMail(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ScheduledReportBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMailWithReport(body: ScheduledReportBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = EmailControllerApiFetchParamCreator(configuration).sendMailWithReport(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * EmailControllerApi - factory interface
 * @export
 */
export const EmailControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScheduledReport(id: number, options?: any) {
            return EmailControllerApiFp(configuration).deleteScheduledReport(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduledReportQuery(id: number, options?: any) {
            return EmailControllerApiFp(configuration).getScheduledReportQuery(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {EmailDetailsDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMail(body: EmailDetailsDTO, options?: any) {
            return EmailControllerApiFp(configuration).sendMail(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ScheduledReportBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMailWithReport(body: ScheduledReportBean, options?: any) {
            return EmailControllerApiFp(configuration).sendMailWithReport(body, options)(fetch, basePath);
        },
    };
};

/**
 * EmailControllerApi - object-oriented interface
 * @export
 * @class EmailControllerApi
 * @extends {BaseAPI}
 */
export class EmailControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailControllerApi
     */
    public deleteScheduledReport(id: number, options?: any) {
        return EmailControllerApiFp(this.configuration).deleteScheduledReport(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailControllerApi
     */
    public getScheduledReportQuery(id: number, options?: any) {
        return EmailControllerApiFp(this.configuration).getScheduledReportQuery(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {EmailDetailsDTO} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailControllerApi
     */
    public sendMail(body: EmailDetailsDTO, options?: any) {
        return EmailControllerApiFp(this.configuration).sendMail(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ScheduledReportBean} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailControllerApi
     */
    public sendMailWithReport(body: ScheduledReportBean, options?: any) {
        return EmailControllerApiFp(this.configuration).sendMailWithReport(body, options)(this.fetch, this.basePath);
    }

}
/**
 * ExternalStatsControllerApi - fetch parameter creator
 * @export
 */
export const ExternalStatsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadExternalStats(dateFrom: string, dateTo: string, options: any = {}): FetchArgs {
            // verify required parameter 'dateFrom' is not null or undefined
            if (dateFrom === null || dateFrom === undefined) {
                throw new RequiredError('dateFrom','Required parameter dateFrom was null or undefined when calling loadExternalStats.');
            }
            // verify required parameter 'dateTo' is not null or undefined
            if (dateTo === null || dateTo === undefined) {
                throw new RequiredError('dateTo','Required parameter dateTo was null or undefined when calling loadExternalStats.');
            }
            const localVarPath = `/api/external-stats/load`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['date_from'] = dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['date_to'] = dateTo;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExternalStatsControllerApi - functional programming interface
 * @export
 */
export const ExternalStatsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadExternalStats(dateFrom: string, dateTo: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ExternalStatsControllerApiFetchParamCreator(configuration).loadExternalStats(dateFrom, dateTo, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ExternalStatsControllerApi - factory interface
 * @export
 */
export const ExternalStatsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadExternalStats(dateFrom: string, dateTo: string, options?: any) {
            return ExternalStatsControllerApiFp(configuration).loadExternalStats(dateFrom, dateTo, options)(fetch, basePath);
        },
    };
};

/**
 * ExternalStatsControllerApi - object-oriented interface
 * @export
 * @class ExternalStatsControllerApi
 * @extends {BaseAPI}
 */
export class ExternalStatsControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} dateFrom 
     * @param {string} dateTo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalStatsControllerApi
     */
    public loadExternalStats(dateFrom: string, dateTo: string, options?: any) {
        return ExternalStatsControllerApiFp(this.configuration).loadExternalStats(dateFrom, dateTo, options)(this.fetch, this.basePath);
    }

}
/**
 * FilterControllerApi - fetch parameter creator
 * @export
 */
export const FilterControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {FilterBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFilter(body: FilterBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createFilter.');
            }
            const localVarPath = `/api/filter`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FilterBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFilter(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteFilter.');
            }
            const localVarPath = `/api/filter/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filter(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling filter.');
            }
            const localVarPath = `/api/filter/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterList(options: any = {}): FetchArgs {
            const localVarPath = `/api/filter`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FilterBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFilter(body: FilterBean, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateFilter.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateFilter.');
            }
            const localVarPath = `/api/filter/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FilterBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FilterControllerApi - functional programming interface
 * @export
 */
export const FilterControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {FilterBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFilter(body: FilterBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FilterBean> {
            const localVarFetchArgs = FilterControllerApiFetchParamCreator(configuration).createFilter(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFilter(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = FilterControllerApiFetchParamCreator(configuration).deleteFilter(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filter(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FilterBean> {
            const localVarFetchArgs = FilterControllerApiFetchParamCreator(configuration).filter(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterList(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponseFilterBean> {
            const localVarFetchArgs = FilterControllerApiFetchParamCreator(configuration).filterList(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {FilterBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFilter(body: FilterBean, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FilterBean> {
            const localVarFetchArgs = FilterControllerApiFetchParamCreator(configuration).updateFilter(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * FilterControllerApi - factory interface
 * @export
 */
export const FilterControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {FilterBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFilter(body: FilterBean, options?: any) {
            return FilterControllerApiFp(configuration).createFilter(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFilter(id: number, options?: any) {
            return FilterControllerApiFp(configuration).deleteFilter(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filter(id: number, options?: any) {
            return FilterControllerApiFp(configuration).filter(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterList(options?: any) {
            return FilterControllerApiFp(configuration).filterList(options)(fetch, basePath);
        },
        /**
         * 
         * @param {FilterBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFilter(body: FilterBean, id: number, options?: any) {
            return FilterControllerApiFp(configuration).updateFilter(body, id, options)(fetch, basePath);
        },
    };
};

/**
 * FilterControllerApi - object-oriented interface
 * @export
 * @class FilterControllerApi
 * @extends {BaseAPI}
 */
export class FilterControllerApi extends BaseAPI {
    /**
     * 
     * @param {FilterBean} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilterControllerApi
     */
    public createFilter(body: FilterBean, options?: any) {
        return FilterControllerApiFp(this.configuration).createFilter(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilterControllerApi
     */
    public deleteFilter(id: number, options?: any) {
        return FilterControllerApiFp(this.configuration).deleteFilter(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilterControllerApi
     */
    public filter(id: number, options?: any) {
        return FilterControllerApiFp(this.configuration).filter(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilterControllerApi
     */
    public filterList(options?: any) {
        return FilterControllerApiFp(this.configuration).filterList(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {FilterBean} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilterControllerApi
     */
    public updateFilter(body: FilterBean, id: number, options?: any) {
        return FilterControllerApiFp(this.configuration).updateFilter(body, id, options)(this.fetch, this.basePath);
    }

}
/**
 * IabCategoryControllerApi - fetch parameter creator
 * @export
 */
export const IabCategoryControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all IAB categories
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iabCategoryList(version: string, options: any = {}): FetchArgs {
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling iabCategoryList.');
            }
            const localVarPath = `/api/iab-category`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IabCategoryControllerApi - functional programming interface
 * @export
 */
export const IabCategoryControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Get all IAB categories
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iabCategoryList(version: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<IabCategoryBean>> {
            const localVarFetchArgs = IabCategoryControllerApiFetchParamCreator(configuration).iabCategoryList(version, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * IabCategoryControllerApi - factory interface
 * @export
 */
export const IabCategoryControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Get all IAB categories
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iabCategoryList(version: string, options?: any) {
            return IabCategoryControllerApiFp(configuration).iabCategoryList(version, options)(fetch, basePath);
        },
    };
};

/**
 * IabCategoryControllerApi - object-oriented interface
 * @export
 * @class IabCategoryControllerApi
 * @extends {BaseAPI}
 */
export class IabCategoryControllerApi extends BaseAPI {
    /**
     * Get all IAB categories
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IabCategoryControllerApi
     */
    public iabCategoryList(version: string, options?: any) {
        return IabCategoryControllerApiFp(this.configuration).iabCategoryList(version, options)(this.fetch, this.basePath);
    }

}
/**
 * ImageControllerApi - fetch parameter creator
 * @export
 */
export const ImageControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Blob} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createImage(image?: Blob, options: any = {}): FetchArgs {
            const localVarPath = `/api/image`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            if (image !== undefined) {
                localVarFormParams.set('image', image as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImage(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteImage.');
            }
            const localVarPath = `/api/image`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImageControllerApi - functional programming interface
 * @export
 */
export const ImageControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Blob} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createImage(image?: Blob, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ImageBean> {
            const localVarFetchArgs = ImageControllerApiFetchParamCreator(configuration).createImage(image, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImage(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ImageControllerApiFetchParamCreator(configuration).deleteImage(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ImageControllerApi - factory interface
 * @export
 */
export const ImageControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {Blob} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createImage(image?: Blob, options?: any) {
            return ImageControllerApiFp(configuration).createImage(image, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImage(id: number, options?: any) {
            return ImageControllerApiFp(configuration).deleteImage(id, options)(fetch, basePath);
        },
    };
};

/**
 * ImageControllerApi - object-oriented interface
 * @export
 * @class ImageControllerApi
 * @extends {BaseAPI}
 */
export class ImageControllerApi extends BaseAPI {
    /**
     * 
     * @param {Blob} [image] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageControllerApi
     */
    public createImage(image?: Blob, options?: any) {
        return ImageControllerApiFp(this.configuration).createImage(image, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageControllerApi
     */
    public deleteImage(id: number, options?: any) {
        return ImageControllerApiFp(this.configuration).deleteImage(id, options)(this.fetch, this.basePath);
    }

}
/**
 * InfoControllerApi - fetch parameter creator
 * @export
 */
export const InfoControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get ssp url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSspUrl(options: any = {}): FetchArgs {
            const localVarPath = `/info/ssp-url`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all time zones
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeZones(options: any = {}): FetchArgs {
            const localVarPath = `/info/time-zones`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get info about own account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        self(options: any = {}): FetchArgs {
            const localVarPath = `/info/account`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InfoControllerApi - functional programming interface
 * @export
 */
export const InfoControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Get ssp url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSspUrl(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = InfoControllerApiFetchParamCreator(configuration).getSspUrl(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Get all time zones
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeZones(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<string>> {
            const localVarFetchArgs = InfoControllerApiFetchParamCreator(configuration).getTimeZones(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Get info about own account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        self(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountInfoBean> {
            const localVarFetchArgs = InfoControllerApiFetchParamCreator(configuration).self(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * InfoControllerApi - factory interface
 * @export
 */
export const InfoControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Get ssp url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSspUrl(options?: any) {
            return InfoControllerApiFp(configuration).getSspUrl(options)(fetch, basePath);
        },
        /**
         * Get all time zones
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeZones(options?: any) {
            return InfoControllerApiFp(configuration).getTimeZones(options)(fetch, basePath);
        },
        /**
         * Get info about own account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        self(options?: any) {
            return InfoControllerApiFp(configuration).self(options)(fetch, basePath);
        },
    };
};

/**
 * InfoControllerApi - object-oriented interface
 * @export
 * @class InfoControllerApi
 * @extends {BaseAPI}
 */
export class InfoControllerApi extends BaseAPI {
    /**
     * Get ssp url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InfoControllerApi
     */
    public getSspUrl(options?: any) {
        return InfoControllerApiFp(this.configuration).getSspUrl(options)(this.fetch, this.basePath);
    }

    /**
     * Get all time zones
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InfoControllerApi
     */
    public getTimeZones(options?: any) {
        return InfoControllerApiFp(this.configuration).getTimeZones(options)(this.fetch, this.basePath);
    }

    /**
     * Get info about own account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InfoControllerApi
     */
    public self(options?: any) {
        return InfoControllerApiFp(this.configuration).self(options)(this.fetch, this.basePath);
    }

}
/**
 * OsControllerApi - fetch parameter creator
 * @export
 */
export const OsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        osList(options: any = {}): FetchArgs {
            const localVarPath = `/api/os`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OsControllerApi - functional programming interface
 * @export
 */
export const OsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        osList(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<OsBean>> {
            const localVarFetchArgs = OsControllerApiFetchParamCreator(configuration).osList(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * OsControllerApi - factory interface
 * @export
 */
export const OsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        osList(options?: any) {
            return OsControllerApiFp(configuration).osList(options)(fetch, basePath);
        },
    };
};

/**
 * OsControllerApi - object-oriented interface
 * @export
 * @class OsControllerApi
 * @extends {BaseAPI}
 */
export class OsControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OsControllerApi
     */
    public osList(options?: any) {
        return OsControllerApiFp(this.configuration).osList(options)(this.fetch, this.basePath);
    }

}
/**
 * PaymentControllerApi - fetch parameter creator
 * @export
 */
export const PaymentControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling _delete.');
            }
            const localVarPath = `/api/payment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PaymentBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(body: PaymentBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling create.');
            }
            const localVarPath = `/api/payment/create`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PaymentBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} range 
         * @param {number} [sellerId] 
         * @param {string} [period] 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredPaymentList(range: string, sellerId?: number, period?: string, status?: string, options: any = {}): FetchArgs {
            // verify required parameter 'range' is not null or undefined
            if (range === null || range === undefined) {
                throw new RequiredError('range','Required parameter range was null or undefined when calling filteredPaymentList.');
            }
            const localVarPath = `/api/payment/filtered`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }

            if (sellerId !== undefined) {
                localVarQueryParameter['sellerId'] = sellerId;
            }

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieve(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling retrieve.');
            }
            const localVarPath = `/api/payment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PaymentBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(body: PaymentBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling update.');
            }
            const localVarPath = `/api/payment/update`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PaymentBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentControllerApi - functional programming interface
 * @export
 */
export const PaymentControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PaymentControllerApiFetchParamCreator(configuration)._delete(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {PaymentBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(body: PaymentBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaymentBean> {
            const localVarFetchArgs = PaymentControllerApiFetchParamCreator(configuration).create(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} range 
         * @param {number} [sellerId] 
         * @param {string} [period] 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredPaymentList(range: string, sellerId?: number, period?: string, status?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponsePaymentBeanInList> {
            const localVarFetchArgs = PaymentControllerApiFetchParamCreator(configuration).filteredPaymentList(range, sellerId, period, status, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieve(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaymentBean> {
            const localVarFetchArgs = PaymentControllerApiFetchParamCreator(configuration).retrieve(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {PaymentBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(body: PaymentBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaymentBean> {
            const localVarFetchArgs = PaymentControllerApiFetchParamCreator(configuration).update(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PaymentControllerApi - factory interface
 * @export
 */
export const PaymentControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(id: number, options?: any) {
            return PaymentControllerApiFp(configuration)._delete(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {PaymentBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(body: PaymentBean, options?: any) {
            return PaymentControllerApiFp(configuration).create(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} range 
         * @param {number} [sellerId] 
         * @param {string} [period] 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredPaymentList(range: string, sellerId?: number, period?: string, status?: string, options?: any) {
            return PaymentControllerApiFp(configuration).filteredPaymentList(range, sellerId, period, status, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieve(id: number, options?: any) {
            return PaymentControllerApiFp(configuration).retrieve(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {PaymentBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(body: PaymentBean, options?: any) {
            return PaymentControllerApiFp(configuration).update(body, options)(fetch, basePath);
        },
    };
};

/**
 * PaymentControllerApi - object-oriented interface
 * @export
 * @class PaymentControllerApi
 * @extends {BaseAPI}
 */
export class PaymentControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentControllerApi
     */
    public _delete(id: number, options?: any) {
        return PaymentControllerApiFp(this.configuration)._delete(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {PaymentBean} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentControllerApi
     */
    public create(body: PaymentBean, options?: any) {
        return PaymentControllerApiFp(this.configuration).create(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} range 
     * @param {number} [sellerId] 
     * @param {string} [period] 
     * @param {string} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentControllerApi
     */
    public filteredPaymentList(range: string, sellerId?: number, period?: string, status?: string, options?: any) {
        return PaymentControllerApiFp(this.configuration).filteredPaymentList(range, sellerId, period, status, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentControllerApi
     */
    public retrieve(id: number, options?: any) {
        return PaymentControllerApiFp(this.configuration).retrieve(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {PaymentBean} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentControllerApi
     */
    public update(body: PaymentBean, options?: any) {
        return PaymentControllerApiFp(this.configuration).update(body, options)(this.fetch, this.basePath);
    }

}
/**
 * PublisherControllerApi - fetch parameter creator
 * @export
 */
export const PublisherControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create new publisher
         * @param {PublisherBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPublisher(body: PublisherBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createPublisher.');
            }
            const localVarPath = `/api/publisher`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PublisherBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePublisher(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deletePublisher.');
            }
            const localVarPath = `/api/publisher/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publisher(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling publisher.');
            }
            const localVarPath = `/api/publisher/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {string} [publisherName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publisherFilteredList(id?: number, publisherName?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/publisher/filtered`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (publisherName !== undefined) {
                localVarQueryParameter['publisherName'] = publisherName;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publisherForList(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling publisherForList.');
            }
            const localVarPath = `/api/publisher/list/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publisherListAll(options: any = {}): FetchArgs {
            const localVarPath = `/api/publisher/all`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PublisherBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePublisher(body: PublisherBean, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updatePublisher.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updatePublisher.');
            }
            const localVarPath = `/api/publisher/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PublisherBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublisherControllerApi - functional programming interface
 * @export
 */
export const PublisherControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Create new publisher
         * @param {PublisherBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPublisher(body: PublisherBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PublisherBean> {
            const localVarFetchArgs = PublisherControllerApiFetchParamCreator(configuration).createPublisher(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePublisher(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PublisherControllerApiFetchParamCreator(configuration).deletePublisher(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publisher(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PublisherBean> {
            const localVarFetchArgs = PublisherControllerApiFetchParamCreator(configuration).publisher(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {string} [publisherName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publisherFilteredList(id?: number, publisherName?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponsePublisherBeanInList> {
            const localVarFetchArgs = PublisherControllerApiFetchParamCreator(configuration).publisherFilteredList(id, publisherName, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publisherForList(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PublisherBeanInList> {
            const localVarFetchArgs = PublisherControllerApiFetchParamCreator(configuration).publisherForList(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publisherListAll(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<NamedBean>> {
            const localVarFetchArgs = PublisherControllerApiFetchParamCreator(configuration).publisherListAll(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {PublisherBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePublisher(body: PublisherBean, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PublisherBean> {
            const localVarFetchArgs = PublisherControllerApiFetchParamCreator(configuration).updatePublisher(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PublisherControllerApi - factory interface
 * @export
 */
export const PublisherControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Create new publisher
         * @param {PublisherBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPublisher(body: PublisherBean, options?: any) {
            return PublisherControllerApiFp(configuration).createPublisher(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePublisher(id: number, options?: any) {
            return PublisherControllerApiFp(configuration).deletePublisher(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publisher(id: number, options?: any) {
            return PublisherControllerApiFp(configuration).publisher(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {string} [publisherName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publisherFilteredList(id?: number, publisherName?: string, options?: any) {
            return PublisherControllerApiFp(configuration).publisherFilteredList(id, publisherName, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publisherForList(id: number, options?: any) {
            return PublisherControllerApiFp(configuration).publisherForList(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publisherListAll(options?: any) {
            return PublisherControllerApiFp(configuration).publisherListAll(options)(fetch, basePath);
        },
        /**
         * 
         * @param {PublisherBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePublisher(body: PublisherBean, id: number, options?: any) {
            return PublisherControllerApiFp(configuration).updatePublisher(body, id, options)(fetch, basePath);
        },
    };
};

/**
 * PublisherControllerApi - object-oriented interface
 * @export
 * @class PublisherControllerApi
 * @extends {BaseAPI}
 */
export class PublisherControllerApi extends BaseAPI {
    /**
     * Create new publisher
     * @param {PublisherBean} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherControllerApi
     */
    public createPublisher(body: PublisherBean, options?: any) {
        return PublisherControllerApiFp(this.configuration).createPublisher(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherControllerApi
     */
    public deletePublisher(id: number, options?: any) {
        return PublisherControllerApiFp(this.configuration).deletePublisher(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherControllerApi
     */
    public publisher(id: number, options?: any) {
        return PublisherControllerApiFp(this.configuration).publisher(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [id] 
     * @param {string} [publisherName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherControllerApi
     */
    public publisherFilteredList(id?: number, publisherName?: string, options?: any) {
        return PublisherControllerApiFp(this.configuration).publisherFilteredList(id, publisherName, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherControllerApi
     */
    public publisherForList(id: number, options?: any) {
        return PublisherControllerApiFp(this.configuration).publisherForList(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherControllerApi
     */
    public publisherListAll(options?: any) {
        return PublisherControllerApiFp(this.configuration).publisherListAll(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {PublisherBean} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherControllerApi
     */
    public updatePublisher(body: PublisherBean, id: number, options?: any) {
        return PublisherControllerApiFp(this.configuration).updatePublisher(body, id, options)(this.fetch, this.basePath);
    }

}
/**
 * ReportControllerApi - fetch parameter creator
 * @export
 */
export const ReportControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReport(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteReport.');
            }
            const localVarPath = `/api/report/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {string} [name] 
         * @param {boolean} [favorite] 
         * @param {boolean} [shared] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredReportList(id?: number, name?: string, favorite?: boolean, shared?: boolean, options: any = {}): FetchArgs {
            const localVarPath = `/api/report/filtered`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (favorite !== undefined) {
                localVarQueryParameter['favorite'] = favorite;
            }

            if (shared !== undefined) {
                localVarQueryParameter['shared'] = shared;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllReports(options: any = {}): FetchArgs {
            const localVarPath = `/api/report/all`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavorites(options: any = {}): FetchArgs {
            const localVarPath = `/api/report/favorites`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportById(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getReportById.');
            }
            const localVarPath = `/api/report/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SavedReportBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveReport(body: SavedReportBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling saveReport.');
            }
            const localVarPath = `/api/report/save`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SavedReportBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StatRequestBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsRequests(body: StatRequestBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling statisticsRequests.');
            }
            const localVarPath = `/api/report/stats`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StatRequestBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportControllerApi - functional programming interface
 * @export
 */
export const ReportControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReport(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ReportControllerApiFetchParamCreator(configuration).deleteReport(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {string} [name] 
         * @param {boolean} [favorite] 
         * @param {boolean} [shared] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredReportList(id?: number, name?: string, favorite?: boolean, shared?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponseSavedReportBeanInList> {
            const localVarFetchArgs = ReportControllerApiFetchParamCreator(configuration).filteredReportList(id, name, favorite, shared, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllReports(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponseSimpleReportBean> {
            const localVarFetchArgs = ReportControllerApiFetchParamCreator(configuration).getAllReports(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavorites(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponseSavedReportBean> {
            const localVarFetchArgs = ReportControllerApiFetchParamCreator(configuration).getFavorites(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportById(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SavedReportBean> {
            const localVarFetchArgs = ReportControllerApiFetchParamCreator(configuration).getReportById(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {SavedReportBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveReport(body: SavedReportBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SavedReportBean> {
            const localVarFetchArgs = ReportControllerApiFetchParamCreator(configuration).saveReport(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {StatRequestBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsRequests(body: StatRequestBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StatResponseBeanRawStatRow> {
            const localVarFetchArgs = ReportControllerApiFetchParamCreator(configuration).statisticsRequests(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ReportControllerApi - factory interface
 * @export
 */
export const ReportControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReport(id: number, options?: any) {
            return ReportControllerApiFp(configuration).deleteReport(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {string} [name] 
         * @param {boolean} [favorite] 
         * @param {boolean} [shared] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filteredReportList(id?: number, name?: string, favorite?: boolean, shared?: boolean, options?: any) {
            return ReportControllerApiFp(configuration).filteredReportList(id, name, favorite, shared, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllReports(options?: any) {
            return ReportControllerApiFp(configuration).getAllReports(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavorites(options?: any) {
            return ReportControllerApiFp(configuration).getFavorites(options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportById(id: number, options?: any) {
            return ReportControllerApiFp(configuration).getReportById(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {SavedReportBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveReport(body: SavedReportBean, options?: any) {
            return ReportControllerApiFp(configuration).saveReport(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {StatRequestBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsRequests(body: StatRequestBean, options?: any) {
            return ReportControllerApiFp(configuration).statisticsRequests(body, options)(fetch, basePath);
        },
    };
};

/**
 * ReportControllerApi - object-oriented interface
 * @export
 * @class ReportControllerApi
 * @extends {BaseAPI}
 */
export class ReportControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportControllerApi
     */
    public deleteReport(id: number, options?: any) {
        return ReportControllerApiFp(this.configuration).deleteReport(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [id] 
     * @param {string} [name] 
     * @param {boolean} [favorite] 
     * @param {boolean} [shared] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportControllerApi
     */
    public filteredReportList(id?: number, name?: string, favorite?: boolean, shared?: boolean, options?: any) {
        return ReportControllerApiFp(this.configuration).filteredReportList(id, name, favorite, shared, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportControllerApi
     */
    public getAllReports(options?: any) {
        return ReportControllerApiFp(this.configuration).getAllReports(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportControllerApi
     */
    public getFavorites(options?: any) {
        return ReportControllerApiFp(this.configuration).getFavorites(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportControllerApi
     */
    public getReportById(id: number, options?: any) {
        return ReportControllerApiFp(this.configuration).getReportById(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {SavedReportBean} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportControllerApi
     */
    public saveReport(body: SavedReportBean, options?: any) {
        return ReportControllerApiFp(this.configuration).saveReport(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {StatRequestBean} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportControllerApi
     */
    public statisticsRequests(body: StatRequestBean, options?: any) {
        return ReportControllerApiFp(this.configuration).statisticsRequests(body, options)(this.fetch, this.basePath);
    }

}
/**
 * ReportingControllerApi - fetch parameter creator
 * @export
 */
export const ReportingControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {StatRequestBean} body 
         * @param {string} apiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsRequestsByApiKey(body: StatRequestBean, apiKey: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling statisticsRequestsByApiKey.');
            }
            // verify required parameter 'apiKey' is not null or undefined
            if (apiKey === null || apiKey === undefined) {
                throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling statisticsRequestsByApiKey.');
            }
            const localVarPath = `/reporting`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiKey !== undefined) {
                localVarQueryParameter['api_key'] = apiKey;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StatRequestBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportingControllerApi - functional programming interface
 * @export
 */
export const ReportingControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {StatRequestBean} body 
         * @param {string} apiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsRequestsByApiKey(body: StatRequestBean, apiKey: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StatResponseBeanRawStatRow> {
            const localVarFetchArgs = ReportingControllerApiFetchParamCreator(configuration).statisticsRequestsByApiKey(body, apiKey, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ReportingControllerApi - factory interface
 * @export
 */
export const ReportingControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {StatRequestBean} body 
         * @param {string} apiKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsRequestsByApiKey(body: StatRequestBean, apiKey: string, options?: any) {
            return ReportingControllerApiFp(configuration).statisticsRequestsByApiKey(body, apiKey, options)(fetch, basePath);
        },
    };
};

/**
 * ReportingControllerApi - object-oriented interface
 * @export
 * @class ReportingControllerApi
 * @extends {BaseAPI}
 */
export class ReportingControllerApi extends BaseAPI {
    /**
     * 
     * @param {StatRequestBean} body 
     * @param {string} apiKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingControllerApi
     */
    public statisticsRequestsByApiKey(body: StatRequestBean, apiKey: string, options?: any) {
        return ReportingControllerApiFp(this.configuration).statisticsRequestsByApiKey(body, apiKey, options)(this.fetch, this.basePath);
    }

}
/**
 * SellersControllerApi - fetch parameter creator
 * @export
 */
export const SellersControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellersJson(options: any = {}): FetchArgs {
            const localVarPath = `/sellers.json`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SellersControllerApi - functional programming interface
 * @export
 */
export const SellersControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellersJson(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SellersBean> {
            const localVarFetchArgs = SellersControllerApiFetchParamCreator(configuration).getSellersJson(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SellersControllerApi - factory interface
 * @export
 */
export const SellersControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellersJson(options?: any) {
            return SellersControllerApiFp(configuration).getSellersJson(options)(fetch, basePath);
        },
    };
};

/**
 * SellersControllerApi - object-oriented interface
 * @export
 * @class SellersControllerApi
 * @extends {BaseAPI}
 */
export class SellersControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellersControllerApi
     */
    public getSellersJson(options?: any) {
        return SellersControllerApiFp(this.configuration).getSellersJson(options)(this.fetch, this.basePath);
    }

}
/**
 * SiteControllerApi - fetch parameter creator
 * @export
 */
export const SiteControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SiteBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSite(body: SiteBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createSite.');
            }
            const localVarPath = `/api/site`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SiteBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSite(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteSite.');
            }
            const localVarPath = `/api/site/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAdsTxt(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling generateAdsTxt.');
            }
            const localVarPath = `/api/site/ads-txt`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        site(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling site.');
            }
            const localVarPath = `/api/site/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteForList(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling siteForList.');
            }
            const localVarPath = `/api/site/list/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteList(options: any = {}): FetchArgs {
            const localVarPath = `/api/site`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteListAll(options: any = {}): FetchArgs {
            const localVarPath = `/api/site/all`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {number} [publisherId] 
         * @param {boolean} [active] 
         * @param {string} [name] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteListByFilters(id?: number, publisherId?: number, active?: boolean, name?: string, type?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/site/filtered`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (publisherId !== undefined) {
                localVarQueryParameter['publisherId'] = publisherId;
            }

            if (active !== undefined) {
                localVarQueryParameter['active'] = active;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} publisherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteListByPublisherId(publisherId: number, options: any = {}): FetchArgs {
            // verify required parameter 'publisherId' is not null or undefined
            if (publisherId === null || publisherId === undefined) {
                throw new RequiredError('publisherId','Required parameter publisherId was null or undefined when calling siteListByPublisherId.');
            }
            const localVarPath = `/api/site/by-publisher`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (publisherId !== undefined) {
                localVarQueryParameter['publisherId'] = publisherId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SiteBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSite(body: SiteBean, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateSite.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateSite.');
            }
            const localVarPath = `/api/site/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SiteBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SiteControllerApi - functional programming interface
 * @export
 */
export const SiteControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SiteBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSite(body: SiteBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SiteBean> {
            const localVarFetchArgs = SiteControllerApiFetchParamCreator(configuration).createSite(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSite(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SiteControllerApiFetchParamCreator(configuration).deleteSite(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAdsTxt(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdsTxtBean> {
            const localVarFetchArgs = SiteControllerApiFetchParamCreator(configuration).generateAdsTxt(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        site(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SiteBean> {
            const localVarFetchArgs = SiteControllerApiFetchParamCreator(configuration).site(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteForList(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SiteBeanInList> {
            const localVarFetchArgs = SiteControllerApiFetchParamCreator(configuration).siteForList(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteList(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponseSiteBeanInList> {
            const localVarFetchArgs = SiteControllerApiFetchParamCreator(configuration).siteList(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteListAll(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SimpleSiteBean>> {
            const localVarFetchArgs = SiteControllerApiFetchParamCreator(configuration).siteListAll(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {number} [publisherId] 
         * @param {boolean} [active] 
         * @param {string} [name] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteListByFilters(id?: number, publisherId?: number, active?: boolean, name?: string, type?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponseSiteBeanInList> {
            const localVarFetchArgs = SiteControllerApiFetchParamCreator(configuration).siteListByFilters(id, publisherId, active, name, type, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} publisherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteListByPublisherId(publisherId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SiteBean>> {
            const localVarFetchArgs = SiteControllerApiFetchParamCreator(configuration).siteListByPublisherId(publisherId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {SiteBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSite(body: SiteBean, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SiteBean> {
            const localVarFetchArgs = SiteControllerApiFetchParamCreator(configuration).updateSite(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SiteControllerApi - factory interface
 * @export
 */
export const SiteControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {SiteBean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSite(body: SiteBean, options?: any) {
            return SiteControllerApiFp(configuration).createSite(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSite(id: number, options?: any) {
            return SiteControllerApiFp(configuration).deleteSite(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAdsTxt(id: number, options?: any) {
            return SiteControllerApiFp(configuration).generateAdsTxt(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        site(id: number, options?: any) {
            return SiteControllerApiFp(configuration).site(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteForList(id: number, options?: any) {
            return SiteControllerApiFp(configuration).siteForList(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteList(options?: any) {
            return SiteControllerApiFp(configuration).siteList(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteListAll(options?: any) {
            return SiteControllerApiFp(configuration).siteListAll(options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {number} [publisherId] 
         * @param {boolean} [active] 
         * @param {string} [name] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteListByFilters(id?: number, publisherId?: number, active?: boolean, name?: string, type?: string, options?: any) {
            return SiteControllerApiFp(configuration).siteListByFilters(id, publisherId, active, name, type, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} publisherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteListByPublisherId(publisherId: number, options?: any) {
            return SiteControllerApiFp(configuration).siteListByPublisherId(publisherId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {SiteBean} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSite(body: SiteBean, id: number, options?: any) {
            return SiteControllerApiFp(configuration).updateSite(body, id, options)(fetch, basePath);
        },
    };
};

/**
 * SiteControllerApi - object-oriented interface
 * @export
 * @class SiteControllerApi
 * @extends {BaseAPI}
 */
export class SiteControllerApi extends BaseAPI {
    /**
     * 
     * @param {SiteBean} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteControllerApi
     */
    public createSite(body: SiteBean, options?: any) {
        return SiteControllerApiFp(this.configuration).createSite(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteControllerApi
     */
    public deleteSite(id: number, options?: any) {
        return SiteControllerApiFp(this.configuration).deleteSite(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteControllerApi
     */
    public generateAdsTxt(id: number, options?: any) {
        return SiteControllerApiFp(this.configuration).generateAdsTxt(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteControllerApi
     */
    public site(id: number, options?: any) {
        return SiteControllerApiFp(this.configuration).site(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteControllerApi
     */
    public siteForList(id: number, options?: any) {
        return SiteControllerApiFp(this.configuration).siteForList(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteControllerApi
     */
    public siteList(options?: any) {
        return SiteControllerApiFp(this.configuration).siteList(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteControllerApi
     */
    public siteListAll(options?: any) {
        return SiteControllerApiFp(this.configuration).siteListAll(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [id] 
     * @param {number} [publisherId] 
     * @param {boolean} [active] 
     * @param {string} [name] 
     * @param {string} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteControllerApi
     */
    public siteListByFilters(id?: number, publisherId?: number, active?: boolean, name?: string, type?: string, options?: any) {
        return SiteControllerApiFp(this.configuration).siteListByFilters(id, publisherId, active, name, type, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} publisherId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteControllerApi
     */
    public siteListByPublisherId(publisherId: number, options?: any) {
        return SiteControllerApiFp(this.configuration).siteListByPublisherId(publisherId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {SiteBean} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteControllerApi
     */
    public updateSite(body: SiteBean, id: number, options?: any) {
        return SiteControllerApiFp(this.configuration).updateSite(body, id, options)(this.fetch, this.basePath);
    }

}
