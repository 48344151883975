import { Badge, UploadFile } from 'antd'
import { UploadChangeParam } from 'antd/lib/upload'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { DirectAdBean } from 'api/base/api'
import { directAdApi } from 'api/directAd'
import { directVideoApi } from 'api/directVideo'
import { imageApi } from 'api/images'

import { routes } from 'utils/routes'
import { sortAdSize } from 'utils/sort'

import {
  FormActionButtons,
  FormContainer,
  FormRow,
  FormSelect,
  FormSwitch,
  FormTextAreaInput,
  FormTextInput,
} from 'components/form'
import { FormUploadImage } from 'components/form/inputs/FormUploadImage'
import { FormUploadVideo } from 'components/form/inputs/FormUploadVideo'

import { useDirectAdFormData } from 'views/directAd/form/useDirectAdFormData'

import RtbDetailsTypeEnum = DirectAdBean.RtbDetailsTypeEnum
import VideoStatusEnum = DirectAdBean.VideoStatusEnum

const { ContentTypeEnum, DirectAdCreativeTypeEnum } = DirectAdBean

export const DirectAdForm: React.FC = () => {
  const history = useHistory()
  const { isCreation, isFormLoading, formName, formState, setFormState, adSizeList } = useDirectAdFormData()

  const [loading, setLoading] = useState(false)

  const redirectToList = () => {
    history.push(routes.directAd.list())
  }

  const submit = () => {
    const bean = formState as DirectAdBean

    switch (bean.contentType) {
      case DirectAdBean.ContentTypeEnum.ADM:
        bean.imageId = undefined
        break
      case DirectAdBean.ContentTypeEnum.IMAGE:
        bean.adm = undefined
        break
      default:
    }

    if (isCreation) {
      directAdApi.create(bean).then(redirectToList)
    } else {
      directAdApi.edit(bean).then(redirectToList)
    }
  }

  const remove = () => {
    directAdApi.delete(formState.id as number).then(redirectToList)
  }

  const contentTypeOptions = []
  if (formState.directAdCreativeType === DirectAdCreativeTypeEnum.BANNER) {
    contentTypeOptions.push(
      { value: ContentTypeEnum.ADM, label: 'Ad markup' },
      { value: ContentTypeEnum.IMAGE, label: 'Image' }
    )
  } else {
    contentTypeOptions.push(
      { value: ContentTypeEnum.ADM, label: 'VAST Code' },
      { value: ContentTypeEnum.VIDEOFILE, label: 'Video File' },
      { value: ContentTypeEnum.VASTURL, label: 'VAST URL' },
      { value: ContentTypeEnum.VPOST, label: 'V_POST' }
    )
  }

  function videoStatusText(status: DirectAdBean.VideoStatusEnum | undefined) {
    switch (status) {
      case VideoStatusEnum.CREATED:
        return 'Saved on the Server'
      case VideoStatusEnum.CONNECTEDWITHAD:
        return 'No Compression'
      case VideoStatusEnum.STARTEDCOMPRESSION:
        return 'Compression Started'
      case VideoStatusEnum.COMPRESSIONFAILED:
        return 'Compression Failed'
      case VideoStatusEnum.COMPRESSED:
        return 'Compressed'
      default:
        return 'Unknown'
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function videoStatusColor(status: DirectAdBean.VideoStatusEnum | undefined) {
    switch (status) {
      case VideoStatusEnum.CREATED:
        return '#23da63'
      case VideoStatusEnum.CONNECTEDWITHAD:
        return '#1f9c92'
      case VideoStatusEnum.STARTEDCOMPRESSION:
        return '#2185a3'
      case VideoStatusEnum.COMPRESSIONFAILED:
        return '#ea0000'
      case VideoStatusEnum.COMPRESSED:
        return '#146eeb'
      default:
        return '#000000'
    }
  }

  function getSizeWidth() {
    const adSize = adSizeList.find((size) => size.id === formState.adSizeId)
    const dimensions = adSize?.name || '300x250'
    return Number(dimensions.substring(0, dimensions.indexOf('x')))
  }

  function getSizeHeight() {
    const adSize = adSizeList.find((size) => size.id === formState.adSizeId)
    const dimensions = adSize?.name || '300x250'
    return Number(dimensions.substring(dimensions.indexOf('x') + 1))
  }

  return (
    <FormContainer title={isCreation ? 'New Direct Ad' : formName} isLoading={isFormLoading}>
      <FormRow>
        <FormSwitch
          span={8}
          label="Active"
          checked={formState.active}
          onChange={() => setFormState({ active: !formState.active })}
        />
      </FormRow>

      <FormRow>
        <FormTextInput
          span={8}
          label="Name"
          value={formState.name}
          onChange={(name) => setFormState({ name })}
          required
        />
      </FormRow>

      <FormSelect
        span={8}
        label="Traffic type"
        value={formState.rtbDetailsType}
        onChange={(rtbDetailsType) => setFormState({ rtbDetailsType })}
        options={[
          { value: RtbDetailsTypeEnum.SITE, label: 'Site' },
          { value: RtbDetailsTypeEnum.APP, label: 'App' },
        ]}
        required
      />

      <FormRow>
        <FormSelect
          span={8}
          label="Ad size"
          value={formState.adSizeId}
          onChange={(adSizeId) => setFormState({ adSizeId })}
          options={adSizeList.map((adSize) => ({ value: adSize.id, label: adSize.name }))}
          required
          selectProps={{ showSearch: true, filterSort: sortAdSize }}
        />
      </FormRow>

      <FormRow>
        <FormTextInput
          span={8}
          label="Click URL"
          value={formState.clickUrl}
          onChange={(clickUrl) => setFormState({ clickUrl })}
          required
        />
      </FormRow>

      <FormRow>
        <FormSelect
          span={4}
          label="Creative type"
          value={formState.directAdCreativeType}
          onChange={(directAdCreativeType) =>
            setFormState({
              directAdCreativeType,
              unwrapVast: false,
              contentType: undefined,
              adm: undefined,
              vastUrl: undefined,
            })
          }
          options={[
            { value: DirectAdCreativeTypeEnum.BANNER, label: 'Banner' },
            { value: DirectAdCreativeTypeEnum.VIDEO, label: 'Video' },
          ]}
          required
        />
        <FormSelect
          span={4}
          label="Content type"
          value={formState.contentType}
          onChange={(contentType) =>
            setFormState({
              contentType,
              unwrapVast: false,
              adm: undefined,
              vastUrl: undefined,
            })
          }
          options={contentTypeOptions}
          required
        />
      </FormRow>

      {(formState.contentType === ContentTypeEnum.VASTURL || formState.contentType === ContentTypeEnum.VPOST) && (
        <FormRow>
          <FormTextInput
            span={8}
            label="VAST URL"
            value={formState.vastUrl}
            onChange={(vastUrl) => setFormState({ vastUrl })}
            required
          />
          <FormSwitch
            span={8}
            label="Unwrap VAST"
            checked={formState.unwrapVast}
            onChange={() => setFormState({ unwrapVast: !formState.unwrapVast })}
          />
        </FormRow>
      )}
      {formState.contentType === ContentTypeEnum.ADM && (
        <FormRow>
          <FormTextAreaInput
            span={12}
            label={formState.directAdCreativeType === DirectAdCreativeTypeEnum.BANNER ? 'Ad markup' : 'VAST Code'}
            value={formState.adm}
            onChange={(adm) => setFormState({ adm })}
            required
            textAreaProps={{ autoSize: { minRows: 16 } }}
          />
        </FormRow>
      )}
      {formState.contentType === ContentTypeEnum.IMAGE && (
        <FormRow>
          <FormUploadImage
            label="Image"
            span={12}
            imageUrl={formState.imageUrl}
            onChange={(info: UploadChangeParam<UploadFile>) => {
              if (info.file.status === 'uploading') {
                setFormState({ imageUrl: undefined })
                setLoading(true)
              }
            }}
            customRequest={(options) => {
              imageApi.upload(options.file as Blob).then((resp) => {
                setFormState({ imageUrl: resp.imageUrl, imageId: resp.id })
                setLoading(false)
              })
            }}
            loading={loading}
            required
            uploadProps={{
              accept: '.jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*',
            }}
          />
        </FormRow>
      )}

      {formState.contentType === ContentTypeEnum.VIDEOFILE && (
        <FormRow>
          <Badge.Ribbon
            text={videoStatusText(formState.videoStatus)}
            color={videoStatusColor(formState.videoStatus)}
            style={{ margin: 15 }}
          >
            <FormUploadVideo
              label="Video"
              span={24}
              width={getSizeWidth()}
              height={getSizeHeight()}
              videoUrl={formState.videoUrl}
              onChange={(info: UploadChangeParam<UploadFile>) => {
                if (info.file.status === 'uploading') {
                  setFormState({ videoUrl: undefined, videoStatus: undefined })
                  setLoading(true)
                }
              }}
              customRequest={(options) => {
                directVideoApi.upload(options.file as Blob).then((resp) => {
                  setFormState({ videoUrl: resp.videoUrl, videoId: resp.id, videoStatus: VideoStatusEnum.CREATED })
                  setLoading(false)
                })
              }}
              loading={loading}
              required
              uploadProps={{
                accept: '.mov, .mp4',
              }}
            />
          </Badge.Ribbon>
        </FormRow>
      )}

      <FormActionButtons
        isCreation={isCreation}
        isLoading={loading}
        onBack={redirectToList}
        onDelete={remove}
        onSubmit={submit}
      />
    </FormContainer>
  )
}
