import { Button, Card, Col, Row, Spin } from 'antd'
import React, { useEffect, useState } from 'react'

import { ScheduledReportBean } from 'api/base/api'
import { reportApi } from 'api/report'

import { createCloseableMessageWithDuration } from 'utils/createMessage'

import { FormRow, FormSelect, FormTextAreaInput, FormTextInput } from 'components/form'
import { useFormStateWithInitState } from 'components/form/utils/useFormState'

interface Props {
  reportId: number
  timeZones: string[]
}

export const ReportFormScheduleTab: React.FC<Props> = ({ reportId, timeZones }) => {
  const [formState, setFormState] = useFormStateWithInitState<ScheduledReportBean>({
    frequency: ScheduledReportBean.FrequencyEnum.DAILY,
    hour: 0,
    timeZone: 'UTC',
  })

  const [isScheduled, setIsScheduled] = useState(false)
  const [isLoading] = useState(false)

  useEffect(() => {
    // setIsLoading(true)
    reportApi.getReportSchedule(reportId).then((res: ScheduledReportBean | void) => {
      if (res) {
        setFormState({
          title: res.title,
          frequency: res.frequency,
          timeZone: res.timeZone,
          hour: res.hour,
          recipients: res.recipients,
        })
        setIsScheduled(true)
      }
      // setIsLoading(false)
    })
  }, [])

  const validateEmails = (input: string[]) => {
    const validEmails = input.every((email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email))
    return validEmails
  }

  const handleRecipients = (value: string) => {
    setFormState({ recipients: value.split('\n') })
  }

  const submit = () => {
    const validFrequencies = Object.values(ScheduledReportBean.FrequencyEnum)

    if (!formState.title || formState.title.length === 0) {
      createCloseableMessageWithDuration('error', 'Please enter title', 10)
      return
    }

    if (formState.title.length > 50) {
      createCloseableMessageWithDuration('error', 'Title should be less than 50 characters', 10)
      return
    }

    if (!formState.frequency || !validFrequencies.includes(formState.frequency)) {
      createCloseableMessageWithDuration('error', 'Invalid frequency selected', 10)
      return
    }

    if (!formState.recipients || formState.recipients.length === 0) {
      createCloseableMessageWithDuration('error', 'At least one recipient is required', 10)
      return
    }

    const isValid = validateEmails(formState.recipients)

    if (!isValid) {
      createCloseableMessageWithDuration('error', 'Enter correct Emails', 10)
      return
    }

    const dto = {
      title: formState.title,
      frequency: formState.frequency,
      savedReportId: reportId,
      hour: formState.hour,
      timeZone: formState.timeZone,
      recipients: formState.recipients,
    } as ScheduledReportBean

    // temporary position. Need to place on then block after api changed
    setIsScheduled(true)

    reportApi.saveSchedule(dto).then((res) => {
      console.log(res)
    })
  }

  const deleteSchedule = () => {
    // temporary position. Need to place on then block after api changed
    setIsScheduled(false)
    setFormState({
      title: '',
      frequency: ScheduledReportBean.FrequencyEnum.DAILY,
      hour: 0,
      timeZone: 'UTC',
      recipients: [],
    })

    reportApi.deleteSchedule(reportId).then((res) => {
      console.log(res)
    })
  }

  return isLoading ? (
    <Card bordered={false}>
      <Row justify="space-around">
        <Col>
          <Spin size="large" />
        </Col>
      </Row>
    </Card>
  ) : (
    <>
      <FormRow>
        <FormTextInput
          span={8}
          xs={12}
          sm={10}
          md={8}
          label="Email Title"
          value={formState.title}
          onChange={(title) => setFormState({ title })}
        />
      </FormRow>
      <FormRow>
        <FormSelect
          span={6}
          label="Period"
          value={formState?.frequency}
          onChange={(frequency) => setFormState({ frequency })} // Update to merge state
          options={[
            { value: ScheduledReportBean.FrequencyEnum.DAILY, label: 'Daily' },
            { value: ScheduledReportBean.FrequencyEnum.WEEKLY, label: 'Weekly' },
            { value: ScheduledReportBean.FrequencyEnum.MONTHLY, label: 'Monthly' },
          ]}
        />
      </FormRow>
      <FormRow>
        <FormSelect
          span={4}
          label="Time Zone"
          value={formState.timeZone}
          onChange={(timeZone) => setFormState({ timeZone })}
          options={timeZones.map((zone) => ({ value: zone, label: zone }))}
          selectProps={{ filterSort: undefined, showSearch: true }}
        />
      </FormRow>
      <FormRow>
        <FormSelect
          span={4}
          label="Hour (24h format)"
          value={formState.hour}
          onChange={(hour) => setFormState({ hour })}
          options={[
            ...Array.from({ length: 24 }, (_, i) => ({
              value: i,
              label: `${i.toString().padStart(2, '0')}:00`, // Changed to template literal
            })),
          ]}
        />
      </FormRow>
      <FormRow>
        <FormTextAreaInput
          span={12}
          textAreaProps={{ autoSize: { minRows: 3, maxRows: 5 } }}
          label="Recipient Emails (line-separated list)"
          value={formState?.recipients?.join('\n')}
          onChange={(value) => handleRecipients(value)}
        />
      </FormRow>
      <FormRow>
        <Col>
          <Button type="primary" onClick={submit}>
            Save
          </Button>
        </Col>
        {isScheduled && (
          <Col>
            <Button type="primary" onClick={deleteSchedule}>
              Delete
            </Button>
          </Col>
        )}
      </FormRow>
    </>
  )
}
