import { Col, Form, Input } from 'antd'
import React from 'react'

interface Props {
  span: string | number
  label?: string
  placeholder?: string
  value?: string
  onChange: (value: string) => void
  required?: boolean

  commentary?: string

  colProps?: React.ComponentProps<typeof Col>
  itemProps?: React.ComponentProps<typeof Form.Item>
  inputProps?: React.ComponentProps<typeof Input>
  xs?: number
  sm?: number
  md?: number
  lg?: number
  xl?: number
  xxl?: number
}

export const FormTextInput: React.FC<Props> = ({
  span,
  label,
  placeholder,
  value,
  onChange,
  required,

  commentary,

  colProps,
  itemProps,
  inputProps,
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
}) => (
  <Col span={span} {...colProps} xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
    <Form.Item label={label} required={required} {...itemProps}>
      {commentary && <span style={{ fontStyle: 'italic', color: 'gray' }}>{commentary}</span>}
      <Input value={value} onChange={(e) => onChange(e.target.value)} placeholder={placeholder} {...inputProps} />
    </Form.Item>
  </Col>
)
