import { Button } from 'antd'
import React from 'react'

import { DspBean } from 'api/base/api'

import { FormNumberInput, FormRow, FormSelect, FormSwitch, FormTextInput } from 'components/form'

import { generateApikey } from 'views/dsp/util/generateApikey'

const { BidRequestAdapterTypeEnum, IabTaxonomyVersionEnum } = DspBean

interface Props {
  formState: Partial<DspBean>
  setFormState: (formState: Partial<DspBean>) => void
}

export const MainTab: React.FC<Props> = ({ formState, setFormState }) => (
  <>
    <FormRow>
      <FormSwitch
        span={4}
        label="Active"
        checked={formState.active}
        onChange={() => setFormState({ active: !formState.active })}
      />
      <FormSwitch
        span={4}
        label="Unwrap VAST"
        checked={formState.unwrapVast}
        onChange={() => setFormState({ unwrapVast: !formState.unwrapVast })}
      />
    </FormRow>
    <FormRow>
      <FormSwitch
        span={4}
        label="Multiple impression"
        checked={formState.multiImp}
        onChange={() => setFormState({ multiImp: !formState.multiImp })}
      />
      <FormSwitch
        span={5}
        label="Accept known buyer only"
        checked={formState.acceptKnownBuyerOnly}
        onChange={() => setFormState({ acceptKnownBuyerOnly: !formState.acceptKnownBuyerOnly })}
      />
    </FormRow>

    <FormRow>
      <FormTextInput
        span={8}
        label="Name"
        value={formState.name}
        onChange={(name) => setFormState({ name })}
        required
      />
    </FormRow>

    <FormRow style={{ alignItems: 'center' }}>
      <FormTextInput
        span={8}
        label="Api Key"
        value={formState.apikey}
        onChange={(apikey) => setFormState({ apikey })}
        required
        inputProps={{ disabled: true }}
      />
      <Button
        type="primary"
        onClick={() => setFormState({ apikey: generateApikey() })}
        style={{ marginBottom: -6, marginLeft: 10 }}
      >
        Generate Api Key
      </Button>
    </FormRow>

    <FormRow>
      <FormTextInput
        span={8}
        label="Registration ID"
        value={formState.registrationId}
        onChange={(registrationId) => setFormState({ registrationId })}
        required
      />
    </FormRow>

    <FormRow>
      <FormTextInput
        span={8}
        label="Endpoint url"
        value={formState.endpointUrl}
        onChange={(endpointUrl) => setFormState({ endpointUrl })}
        required
      />
    </FormRow>

    <FormRow>
      <FormSelect
        span={8}
        label="DSP adapter"
        value={formState.bidRequestAdapterType}
        onChange={(bidRequestAdapterType) => setFormState({ bidRequestAdapterType })}
        options={[
          { value: BidRequestAdapterTypeEnum.Default, label: 'Default' },
          { value: BidRequestAdapterTypeEnum.Admixer, label: 'Admixer' },
          { value: BidRequestAdapterTypeEnum.NHNAce, label: 'NHN Ace' },
          { value: BidRequestAdapterTypeEnum.Magnite, label: 'Magnite' },
          { value: BidRequestAdapterTypeEnum.Index, label: 'Index' },
          { value: BidRequestAdapterTypeEnum.OneTag, label: 'OneTag' },
          { value: BidRequestAdapterTypeEnum.Pubmatic, label: 'Pubmatic' },
          { value: BidRequestAdapterTypeEnum.AdpackerVideo, label: 'Adpacker-Video' },
          { value: BidRequestAdapterTypeEnum.AdpackerDisplay, label: 'Adpacker-Display' },
          // Adding MezzoMedia to adapter option list for DSP's
          { value: BidRequestAdapterTypeEnum.MezzoMedia, label: 'MezzoMedia' },
          { value: BidRequestAdapterTypeEnum.MotivIntelligence, label: 'MotivIntelligence' },
        ]}
        required
        selectProps={{ showSearch: true, filterSort: undefined }}
      />
    </FormRow>

    <FormRow>
      <FormSelect
        span={8}
        label="Compression type"
        value={formState.compressionType}
        onChange={(compressionType) => setFormState({ compressionType })}
        options={Object.values(DspBean.CompressionTypeEnum).map((compressionType) => ({
          value: compressionType,
          label: compressionType,
        }))}
        required
        selectProps={{ filterSort: undefined }}
      />
    </FormRow>

    <FormRow>
      <FormNumberInput
        span={8}
        label="Timeout in milliseconds"
        value={formState.timeout}
        onChange={(timeout) => setFormState({ timeout })}
        inputProps={{ min: 10, max: 10000, controls: true }}
      />
    </FormRow>

    <FormRow>
      <FormNumberInput
        span={8}
        label="QPS Limit"
        value={formState.qpsLimit}
        onChange={(qpsLimit) => setFormState({ qpsLimit })}
        inputProps={{ min: 1, max: 2147483647, controls: true }}
      />
    </FormRow>

    <FormRow>
      <FormTextInput
        span={8}
        label="User sync endpoint"
        value={formState.userSyncEndpoint}
        onChange={(userSyncEndpoint) => setFormState({ userSyncEndpoint })}
        commentary="use {USER_SYNC} macro for redirection link"
      />
    </FormRow>

    <FormRow>
      <FormTextInput
        span={8}
        label="User sync UID macro"
        value={formState.userSyncUidMacro}
        onChange={(userSyncUidMacro) => setFormState({ userSyncUidMacro })}
      />
    </FormRow>

    <FormRow>
      <FormSelect
        span={8}
        label="Taxonomy version"
        value={formState.iabTaxonomyVersion}
        onChange={(iabTaxonomyVersion) => setFormState({ iabTaxonomyVersion })}
        options={[
          { value: IabTaxonomyVersionEnum._10, label: '1.0' },
          { value: IabTaxonomyVersionEnum._22, label: '2.2' },
        ]}
      />
    </FormRow>

    <FormRow>
      <FormNumberInput
        span={8}
        label="Sampling rate"
        value={formState.samplingRate}
        onChange={(samplingRate) => setFormState({ samplingRate })}
        inputProps={{ min: 1, controls: true }}
      />
    </FormRow>
  </>
)
